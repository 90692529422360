export const Memfault = ({ ...props }) => (
	<svg
		width="32.142941"
		height="28.72525"
		viewBox="0 0 32.142941 28.72525"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<title>Memfault logo</title>
		<path
			d="m 31.44659,18.166375 -2.2797,-1.3158 -10.8546,6.3054 c -1.3989,0.8077 -3.1087,0.8077 -4.5077,0 l -10.8546,-6.3054 -2.27967,1.3158 c -0.89376,0.5211 -0.89376,1.8109 0,2.332 l 13.12137,7.6211 c 1.3989,0.8077 3.1087,0.8077 4.5076,0 l 13.1214,-7.6211 c 0.8938,-0.5211 0.8938,-1.8109 0,-2.332"
			fill="#39c0ce"
		/>
		<path
			d="m 13.81769,10.545675 c 1.3989,-0.8077 3.1087,-0.8077 4.5077,0 l 6.5603,3.82 4.3161,-2.4957 -10.8871,-6.3211 c -1.399,-0.8077 -3.111,-0.7874 -4.5099,0.0203 l -10.8546,6.3053 4.3035,2.4875 6.5384,-3.7999 z"
			fill="#b1e5e9"
		/>
		<path
			d="m 13.81759,10.545275 -6.5671,3.8171 6.5671,3.8171 c 1.3989,0.8077 3.1087,0.8077 4.5077,0 l 6.5671,-3.8171 -6.5671,-3.8171 c -1.399,-0.8077 -3.1088,-0.8077 -4.5077,0 z"
			fill="#ffffff"
		/>
		<path
			d="m 0.69635,10.558875 2.27974,1.3158 10.8546,-6.3053 c 1.3989,-0.8078 3.1087,-0.8078 4.5076,0 l 10.8546,6.3053 2.2797,-1.3158 c 0.8938,-0.5211 0.8938,-1.8108 0,-2.3319 l -13.1213,-7.6212 c -1.399,-0.8077 -3.1087,-0.8077 -4.5077,0 l -13.12133,7.6212 c -0.89376,0.5211 -0.89376,1.8108 0,2.3319"
			fill="#39c0ce"
		/>
	</svg>
)
