export const NRPlus = ({ ...props }) => (
	<svg
		width="121.17606mm"
		height="100.0001mm"
		viewBox="0 0 121.17606 100.0001"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<g transform="translate(-30.269987,-88.073692)">
			<g>
				<path
					style="opacity:1;fill:#d1203a;fill-opacity:1;stroke-width:0.353623;paint-order:stroke fill markers;stop-color:#000000"
					d="m 177.71289,437.7207 a 63.307086,63.307086 0 0 0 -63.26172,62.66797 h -0.0449 v 0.63867 74.95118 h 34.01563 v -18.87696 -56.07422 -0.63867 h 0.0352 a 29.291339,29.291339 0 0 1 29.25586,-28.65234 29.291339,29.291339 0 0 1 29.25586,28.65234 h 0.0352 v 0.63867 56.07422 18.87696 h 34.01562 v -74.95118 -0.63867 h -0.0449 A 63.307086,63.307086 0 0 0 177.71289,437.7207 Z"
					transform="scale(0.26458333)"
				/>
				<path
					style="fill:#949599;fill-opacity:1;stroke-width:0.0992145;paint-order:stroke fill markers;stop-color:#000000"
					d="M 101.44652,88.073692 V 95.57401 A 42.499999,42.499999 0 0 1 143.94574,138.07374 42.499999,42.499999 0 0 1 101.446,180.57399 42.499999,42.499999 0 0 1 63.121211,156.36462 H 54.91241 a 50,50 0 0 0 46.53359,31.70917 50,50 0 0 0 50.00005,-50.00005 50,50 0 0 0 -49.99953,-50.000048 z"
				/>
				<path
					style="fill:#949599;fill-opacity:1;stroke-width:0.0719307;paint-order:stroke fill markers;stop-color:#000000"
					d="m 101.44652,101.82376 v 7.4998 a 28.75,28.75 0 0 1 28.74915,28.75018 28.75,28.75 0 0 1 -28.74967,28.75018 28.75,28.75 0 0 1 -22.152649,-10.4593 h -9.136393 a 36.249998,36.249998 0 0 0 31.289042,17.9591 36.249998,36.249998 0 0 0 36.24999,-36.24998 36.249998,36.249998 0 0 0 -36.24947,-36.24998 z"
				/>
				<path
					style="fill:#d1203a;fill-opacity:1;stroke-width:0.108037;paint-order:stroke fill markers;stop-color:#000000"
					d="m 97.44597,123.074 v 10.99985 H 86.44612 v 8.00003 h 10.99985 v 10.99984 h 8.00003 v -10.99984 h 10.99984 v -8.00003 H 105.446 V 123.074 Z"
				/>
				<path
					style="opacity:1;fill:#d1203a;fill-opacity:1;stroke-width:0.368281;paint-order:stroke fill markers;stop-color:#000000"
					d="m 333.97656,437.7207 c -37.63382,0.0227 -68.23531,27.97195 -68.61523,62.66797 h -0.0488 v 0.63867 74.95118 h 33.42383 v -18.87696 -52.90326 -0.63867 h 0.0371 c 0.37659,-15.91328 17.93919,-31.80781 35.20312,-31.8233 2.4076,0.002 4.75028,0.25984 7.00586,0.73047 l 26.41016,-26.72657 c -9.89162,-5.09964 -21.28722,-8.01207 -33.41602,-8.01953 z"
					transform="scale(0.26458333)"
				/>
			</g>
		</g>
	</svg>
)
