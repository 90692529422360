import type {
	ColorSpecification,
	DataDrivenPropertyValueSpecification,
	PropertyValueSpecification,
	StyleSpecification,
} from 'maplibre-gl'

export const mapStyle = ({
	region,
	mapName,
}: {
	region: string
	mapName: string
}): StyleSpecification => ({
	version: 8,
	sources: {
		esri: {
			attribution:
				'Esri, HERE, Garmin, FAO, NOAA, USGS, © OpenStreetMap contributors, and the GIS User Community',
			maxzoom: 15,
			tiles: [
				`https://maps.geo.${region}.amazonaws.com/maps/v0/maps/${mapName}/tiles/{z}/{x}/{y}`,
			],
			type: 'vector',
		},
	},
	sprite: `https://maps.geo.${region}.amazonaws.com/maps/v0/maps/${mapName}/sprites/sprites`,
	glyphs: `https://maps.geo.${region}.amazonaws.com/maps/v0/maps/${mapName}/glyphs/{fontstack}/{range}.pbf`,
	layers: [
		{
			id: 'background',
			paint: { 'background-color': 'rgba(12, 36, 56, 1)' },
			type: 'background',
		},
		{
			id: 'Land',
			layout: {},
			minzoom: 0,
			paint: {
				'fill-color': <
					DataDrivenPropertyValueSpecification<ColorSpecification>
				>{
					stops: [
						[0, '#3b3c3c'],
						[12, '#353636'],
						[15, '#323333'],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Land',
			type: 'fill',
		},
		{
			id: 'Urban area',
			layout: {},
			maxzoom: 15,
			minzoom: 5,
			paint: {
				'fill-color': <
					DataDrivenPropertyValueSpecification<ColorSpecification>
				>{
					stops: [
						[5, '#2F3030'],
						[10, '#323333'],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Urban area',
			type: 'fill',
		},
		{
			id: 'Openspace or forest',
			layout: {},
			minzoom: 12,
			paint: {
				'fill-color': <
					DataDrivenPropertyValueSpecification<ColorSpecification>
				>{
					stops: [
						[7, '#373837'],
						[9, '#373938'],
					],
				},
				'fill-outline-color': '#373938',
			},
			source: 'esri',
			'source-layer': 'Openspace or forest',
			type: 'fill',
		},
		{
			id: 'Admin0 forest or park',
			layout: {},
			minzoom: 7,
			paint: {
				'fill-color': <
					DataDrivenPropertyValueSpecification<ColorSpecification>
				>{
					stops: [
						[7, '#373837'],
						[9, '#373938'],
					],
				},
				'fill-outline-color': '#373938',
			},
			source: 'esri',
			'source-layer': 'Admin0 forest or park',
			type: 'fill',
		},
		{
			id: 'Admin1 forest or park',
			layout: {},
			minzoom: 8,
			paint: {
				'fill-color': <
					DataDrivenPropertyValueSpecification<ColorSpecification>
				>{
					stops: [
						[7, '#373837'],
						[9, '#373938'],
					],
				},
				'fill-outline-color': '#373938',
			},
			source: 'esri',
			'source-layer': 'Admin1 forest or park',
			type: 'fill',
		},
		{
			id: 'Zoo',
			layout: {},
			minzoom: 12,
			paint: { 'fill-color': '#373938' },
			source: 'esri',
			'source-layer': 'Zoo',
			type: 'fill',
		},
		{
			filter: ['==', '_symbol', 1],
			id: 'Airport/Airport property',
			layout: {},
			minzoom: 9,
			paint: { 'fill-color': '#373838' },
			source: 'esri',
			'source-layer': 'Airport',
			type: 'fill',
		},
		{
			filter: ['==', '_symbol', 0],
			id: 'Airport/Airport runway',
			layout: {},
			minzoom: 11,
			paint: { 'fill-color': '#2d2e2e' },
			source: 'esri',
			'source-layer': 'Airport',
			type: 'fill',
		},
		{
			id: 'Pedestrian',
			layout: {},
			minzoom: 14,
			paint: { 'fill-color': '#353636' },
			source: 'esri',
			'source-layer': 'Pedestrian',
			type: 'fill',
		},
		{
			id: 'Park or farming',
			layout: {},
			minzoom: 12,
			paint: { 'fill-color': '#373938' },
			source: 'esri',
			'source-layer': 'Park or farming',
			type: 'fill',
		},
		{
			id: 'Beach',
			layout: {},
			minzoom: 13,
			paint: { 'fill-pattern': 'Special area of interest/Sand' },
			source: 'esri',
			'source-layer': 'Beach',
			type: 'fill',
		},
		{
			filter: ['==', '_symbol', 12],
			id: 'Special area of interest/Garden path',
			layout: { visibility: 'none' },
			minzoom: 14,
			paint: { 'fill-color': '#353636', 'fill-outline-color': '#EBE8E8' },
			source: 'esri',
			'source-layer': 'Special area of interest',
			type: 'fill',
		},
		{
			filter: ['==', '_symbol', 15],
			id: 'Special area of interest/Parking',
			layout: {},
			minzoom: 14,
			paint: { 'fill-color': '#353636' },
			source: 'esri',
			'source-layer': 'Special area of interest',
			type: 'fill',
		},
		{
			filter: ['==', '_symbol', 11],
			id: 'Special area of interest/Green openspace',
			layout: {},
			minzoom: 14,
			paint: { 'fill-color': '#343635' },
			source: 'esri',
			'source-layer': 'Special area of interest',
			type: 'fill',
		},
		{
			filter: ['==', '_symbol', 8],
			id: 'Special area of interest/Grass',
			layout: {},
			minzoom: 14,
			paint: { 'fill-color': '#3c3e3d' },
			source: 'esri',
			'source-layer': 'Special area of interest',
			type: 'fill',
		},
		{
			filter: ['==', '_symbol', 1],
			id: 'Special area of interest/Baseball field or other grounds',
			layout: {},
			minzoom: 14,
			paint: { 'fill-color': '#3a3b3a' },
			source: 'esri',
			'source-layer': 'Special area of interest',
			type: 'fill',
		},
		{
			filter: ['==', '_symbol', 13],
			id: 'Special area of interest/Groundcover',
			layout: {},
			minzoom: 14,
			paint: {
				'fill-opacity': 0.5,
				'fill-pattern': 'Special area of interest/Groundcover',
			},
			source: 'esri',
			'source-layer': 'Special area of interest',
			type: 'fill',
		},
		{
			filter: ['==', '_symbol', 5],
			id: 'Special area of interest/Field or court exterior',
			layout: {},
			minzoom: 14,
			paint: { 'fill-color': '#404140' },
			source: 'esri',
			'source-layer': 'Special area of interest',
			type: 'fill',
		},
		{
			filter: ['==', '_symbol', 4],
			id: 'Special area of interest/Football field or court',
			layout: {},
			minzoom: 14,
			paint: { 'fill-color': '#3a3b3a', 'fill-outline-color': '#353636' },
			source: 'esri',
			'source-layer': 'Special area of interest',
			type: 'fill',
		},
		{
			filter: ['==', '_symbol', 10],
			id: 'Special area of interest/Hardcourt',
			layout: {},
			minzoom: 14,
			paint: { 'fill-color': '#2a2c2b', 'fill-outline-color': '#353636' },
			source: 'esri',
			'source-layer': 'Special area of interest',
			type: 'fill',
		},
		{
			filter: ['==', '_symbol', 14],
			id: 'Special area of interest/Mulch or dirt',
			layout: {},
			minzoom: 14,
			paint: { 'fill-color': '#4c4d49' },
			source: 'esri',
			'source-layer': 'Special area of interest',
			type: 'fill',
		},
		{
			filter: ['==', '_symbol', 0],
			id: 'Special area of interest/Athletic track',
			layout: {},
			minzoom: 14,
			paint: { 'fill-color': '#3b3b3b', 'fill-outline-color': '#333232' },
			source: 'esri',
			'source-layer': 'Special area of interest',
			type: 'fill',
		},
		{
			filter: ['==', '_symbol', 6],
			id: 'Special area of interest/Sand',
			layout: {},
			minzoom: 14,
			paint: { 'fill-pattern': 'Special area of interest/Sand' },
			source: 'esri',
			'source-layer': 'Special area of interest',
			type: 'fill',
		},
		{
			filter: ['==', '_symbol', 16],
			id: 'Special area of interest/Rock or gravel',
			layout: {},
			minzoom: 14,
			paint: { 'fill-pattern': 'Special area of interest/Rock or gravel' },
			source: 'esri',
			'source-layer': 'Special area of interest',
			type: 'fill',
		},
		{
			filter: ['==', '_symbol', 7],
			id: 'Special area of interest/Water',
			layout: {},
			minzoom: 15,
			paint: { 'fill-color': 'rgba(12, 36, 56, 1)' },
			source: 'esri',
			'source-layer': 'Special area of interest',
			type: 'fill',
		},
		{
			id: 'Water line small scale',
			layout: { 'line-join': 'round', visibility: 'none' },
			maxzoom: 5,
			minzoom: 1,
			paint: { 'line-color': '#2B2E2F', 'line-width': 0.5 },
			source: 'esri',
			'source-layer': 'Water line small scale',
			type: 'line',
		},
		{
			id: 'Water line medium scale',
			layout: { 'line-join': 'round' },
			maxzoom: 7,
			minzoom: 6,
			paint: {
				'line-color': '#2B2E2F',
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[5, 0.5],
						[7, 0.7],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Water line medium scale',
			type: 'line',
		},
		{
			id: 'Water line large scale',
			layout: { 'line-cap': 'round', 'line-join': 'round' },
			maxzoom: 11,
			minzoom: 7,
			paint: {
				'line-color': <
					DataDrivenPropertyValueSpecification<ColorSpecification>
				>{
					stops: [
						[7, '#2B2E2F'],
						[10, '#272A2B'],
					],
				},
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[7, 0.7],
						[11, 0.8],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Water line large scale',
			type: 'line',
		},
		{
			filter: ['==', '_symbol', 5],
			id: 'Water line/Waterfall',
			layout: { 'line-join': 'round' },
			minzoom: 11,
			paint: {
				'line-color': '#222628',
				'line-dasharray': [5, 5],
				'line-width': 0.8,
			},
			source: 'esri',
			'source-layer': 'Water line',
			type: 'line',
		},
		{
			filter: ['==', '_symbol', 2],
			id: 'Water line/Dam or weir',
			layout: { 'line-cap': 'round', 'line-join': 'round' },
			minzoom: 11,
			paint: {
				'line-color': '#262525',
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[11, 0.7],
						[14, 0.7],
						[17, 2],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Water line',
			type: 'line',
		},
		{
			filter: ['==', '_symbol', 3],
			id: 'Water line/Levee/1',
			layout: { 'line-join': 'round' },
			minzoom: 11,
			paint: {
				'line-color': '#222628',
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[11, 0.7],
						[14, 0.7],
						[17, 2],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Water line',
			type: 'line',
		},
		{
			filter: ['==', '_symbol', 3],
			id: 'Water line/Levee/0',
			layout: {
				'icon-allow-overlap': true,
				'icon-image': 'Water line/Levee/0',
				'icon-padding': 1,
				'icon-rotation-alignment': 'map',
				'symbol-avoid-edges': true,
				'symbol-placement': 'line',
				'symbol-spacing': 15,
			},
			minzoom: 13,
			paint: {},
			source: 'esri',
			'source-layer': 'Water line',
			type: 'symbol',
		},
		{
			filter: ['==', '_symbol', 1],
			id: 'Water line/Canal or ditch',
			layout: { 'line-cap': 'round' },
			minzoom: 11,
			paint: {
				'line-color': '#222628',
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[11, 0.8],
						[14, 0.8],
						[17, 2],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Water line',
			type: 'line',
		},
		{
			filter: ['==', '_symbol', 4],
			id: 'Water line/Stream or river intermittent',
			layout: {},
			minzoom: 11,
			paint: {
				'line-color': '#222628',
				'line-dasharray': [7, 3],
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[11, 0.8],
						[14, 0.8],
						[17, 2],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Water line',
			type: 'line',
		},
		{
			filter: ['==', '_symbol', 0],
			id: 'Water line/Stream or river',
			layout: { 'line-cap': 'round' },
			minzoom: 11,
			paint: {
				'line-color': '#222628',
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[11, 0.8],
						[14, 0.8],
						[17, 2],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Water line',
			type: 'line',
		},
		{
			id: 'Marine area',
			layout: {},
			minzoom: 4,
			paint: { 'fill-color': 'rgba(12, 36, 56, 1)' },
			source: 'esri',
			'source-layer': 'Marine area',
			type: 'fill',
		},
		{
			id: 'Water area small scale',
			layout: {},
			maxzoom: 5,
			minzoom: 1,
			paint: { 'fill-color': 'rgba(12, 36, 56, 1)' },
			source: 'esri',
			'source-layer': 'Water area small scale',
			type: 'fill',
		},
		{
			filter: ['==', '_symbol', 1],
			id: 'Water area medium scale/Lake intermittent',
			layout: {},
			maxzoom: 7,
			minzoom: 5,
			paint: { 'fill-pattern': 'Water area/Lake or river intermittent' },
			source: 'esri',
			'source-layer': 'Water area medium scale',
			type: 'fill',
		},
		{
			filter: ['==', '_symbol', 0],
			id: 'Water area medium scale/Lake or river',
			layout: {},
			maxzoom: 7,
			minzoom: 5,
			paint: { 'fill-color': 'rgba(12, 36, 56, 1)' },
			source: 'esri',
			'source-layer': 'Water area medium scale',
			type: 'fill',
		},
		{
			filter: ['==', '_symbol', 1],
			id: 'Water area large scale/Lake intermittent',
			layout: {},
			maxzoom: 11,
			minzoom: 7,
			paint: { 'fill-pattern': 'Water area/Lake or river intermittent' },
			source: 'esri',
			'source-layer': 'Water area large scale',
			type: 'fill',
		},
		{
			filter: ['==', '_symbol', 0],
			id: 'Water area large scale/Lake or river',
			layout: {},
			maxzoom: 11,
			minzoom: 7,
			paint: { 'fill-color': 'rgba(12, 36, 56, 1)' },
			source: 'esri',
			'source-layer': 'Water area large scale',
			type: 'fill',
		},
		{
			filter: ['==', '_symbol', 7],
			id: 'Water area/Lake, river or bay',
			layout: {},
			minzoom: 11,
			paint: { 'fill-color': 'rgba(12, 36, 56, 1)' },
			source: 'esri',
			'source-layer': 'Water area',
			type: 'fill',
		},
		{
			filter: ['==', '_symbol', 6],
			id: 'Water area/Lake or river intermittent',
			layout: {},
			minzoom: 11,
			paint: { 'fill-pattern': 'Water area/Lake or river intermittent' },
			source: 'esri',
			'source-layer': 'Water area',
			type: 'fill',
		},
		{
			filter: ['==', '_symbol', 4],
			id: 'Water area/Inundated area',
			layout: {},
			minzoom: 11,
			paint: { 'fill-pattern': 'Water area/Inundated area' },
			source: 'esri',
			'source-layer': 'Water area',
			type: 'fill',
		},
		{
			filter: ['==', '_symbol', 3],
			id: 'Water area/Swamp or marsh',
			layout: {},
			minzoom: 11,
			paint: { 'fill-pattern': 'Water area/Swamp or marsh' },
			source: 'esri',
			'source-layer': 'Water area',
			type: 'fill',
		},
		{
			filter: ['==', '_symbol', 1],
			id: 'Water area/Playa',
			layout: {},
			minzoom: 11,
			paint: { 'fill-pattern': 'Water area/Playa' },
			source: 'esri',
			'source-layer': 'Water area',
			type: 'fill',
		},
		{
			filter: ['==', '_symbol', 5],
			id: 'Water area/Dam or weir',
			layout: {},
			minzoom: 11,
			paint: { 'fill-color': '#383939', 'fill-outline-color': '#2e2f2f' },
			source: 'esri',
			'source-layer': 'Water area',
			type: 'fill',
		},
		{
			filter: ['==', '_symbol', 2],
			id: 'Special area of interest/Bike, walk or pedestrian',
			layout: {},
			minzoom: 14,
			paint: { 'fill-color': '#353636' },
			source: 'esri',
			'source-layer': 'Special area of interest',
			type: 'fill',
		},
		{
			id: 'Railroad/2',
			layout: { 'line-join': 'round' },
			minzoom: 12,
			paint: {
				'line-color': '#3a3b3b',
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[12, 1.5],
						[14, 2.5],
						[17, 3],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Railroad',
			type: 'line',
		},
		{
			id: 'Railroad/1',
			layout: { 'line-join': 'round' },
			minzoom: 12,
			paint: {
				'line-color': '#292828',
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[12, 0.5],
						[14, 1],
						[17, 1.75],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Railroad',
			type: 'line',
		},
		{
			filter: ['all', ['==', '_symbol', 1], ['!in', 'Viz', 3]],
			id: 'Ferry/Rail ferry/2',
			layout: { 'line-join': 'round' },
			minzoom: 12,
			paint: {
				'line-color': '#3a3b3b',
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[12, 1.5],
						[14, 2.5],
						[17, 3],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Ferry',
			type: 'line',
		},
		{
			filter: ['all', ['==', '_symbol', 1], ['!in', 'Viz', 3]],
			id: 'Ferry/Rail ferry/1',
			layout: { 'line-join': 'round' },
			minzoom: 12,
			paint: {
				'line-color': '#292828',
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[12, 0.5],
						[14, 1],
						[17, 1.75],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Ferry',
			type: 'line',
		},
		{
			filter: ['==', '_symbol', 0],
			id: 'Special area of interest line/Dock or pier',
			layout: { 'line-cap': 'round', 'line-join': 'round' },
			minzoom: 15,
			paint: {
				'line-color': '#353636',
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[15, 0.7],
						[17, 1.2],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Special area of interest line',
			type: 'line',
		},
		{
			filter: ['==', '_symbol', 6],
			id: 'Special area of interest line/Sports field',
			layout: { 'line-cap': 'round', 'line-join': 'round' },
			minzoom: 15,
			paint: {
				'line-color': '#353636',
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[15, 0.7],
						[17, 1.2],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Special area of interest line',
			type: 'line',
		},
		{
			id: 'Building/Shadow',
			layout: {},
			minzoom: 16,
			paint: {
				'fill-color': '#2e2f2f',
				'fill-translate': <PropertyValueSpecification<[number, number]>>{
					stops: [
						[15, [0, 0]],
						[18, [2, 2]],
					],
				},
				'fill-translate-anchor': 'viewport',
			},
			source: 'esri',
			'source-layer': 'Building',
			type: 'fill',
		},
		{
			id: 'Building',
			layout: {},
			minzoom: 15,
			paint: { 'fill-color': '#383939', 'fill-outline-color': '#2e2f2f' },
			source: 'esri',
			'source-layer': 'Building',
			type: 'fill',
		},
		{
			filter: ['==', '_symbol', 5],
			id: 'Special area of interest line/Parking lot',
			layout: { 'line-cap': 'round', 'line-join': 'round' },
			minzoom: 15,
			paint: {
				'line-color': '#434444',
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[15, 0.7],
						[17, 1.2],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Special area of interest line',
			type: 'line',
		},
		{
			id: 'Trail or path/1',
			layout: { 'line-cap': 'round', 'line-join': 'round' },
			minzoom: 15,
			paint: {
				'line-color': '#303131',
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[14, 1.5],
						[16, 3.3],
						[18, 4],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Trail or path',
			type: 'line',
		},
		{
			filter: ['all', ['==', '_symbol', 10], ['!in', 'Viz', 3]],
			id: 'Road/4WD/1',
			layout: { 'line-join': 'round' },
			minzoom: 13,
			paint: {
				'line-color': '#2A2B2B',
				'line-dasharray': [2, 1],
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[11, 1.5],
						[14, 3.3],
						[18, 8.3],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Road',
			type: 'line',
		},
		{
			filter: ['all', ['==', '_symbol', 8], ['!in', 'Viz', 3]],
			id: 'Road/Service/1',
			layout: { 'line-cap': 'round', 'line-join': 'round' },
			minzoom: 13,
			paint: {
				'line-color': '#2A2B2B',
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[11, 1.5],
						[14, 3.3],
						[18, 8.3],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Road',
			type: 'line',
		},
		{
			filter: ['all', ['==', '_symbol', 7], ['!in', 'Viz', 3]],
			id: 'Road/Local/1',
			layout: { 'line-cap': 'round', 'line-join': 'round' },
			minzoom: 12,
			paint: {
				'line-color': '#2A2B2B',
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[11, 1.5],
						[14, 4],
						[16, 6],
						[18, 17.3],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Road',
			type: 'line',
		},
		{
			filter: ['all', ['==', '_symbol', 9], ['!in', 'Viz', 3]],
			id: 'Road/Pedestrian/1',
			layout: { 'line-cap': 'round', 'line-join': 'round' },
			minzoom: 15,
			paint: {
				'line-color': '#303131',
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[14, 1.5],
						[16, 3.3],
						[18, 4],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Road',
			type: 'line',
		},
		{
			filter: ['all', ['==', '_symbol', 6], ['!in', 'Viz', 3]],
			id: 'Road/Minor, ramp or traffic circle/1',
			layout: { 'line-cap': 'round', 'line-join': 'round' },
			minzoom: 11,
			paint: {
				'line-color': '#2A2B2B',
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[11, 1],
						[14, 4],
						[16, 9.6],
						[18, 17.3],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Road',
			type: 'line',
		},
		{
			filter: ['all', ['==', '_symbol', 5], ['!in', 'Viz', 3]],
			id: 'Road/Minor/1',
			layout: { 'line-cap': 'round', 'line-join': 'round' },
			minzoom: 11,
			paint: {
				'line-color': '#2A2B2B',
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[11, 2.6],
						[14, 5.6],
						[16, 9.6],
						[18, 17.3],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Road',
			type: 'line',
		},
		{
			filter: ['all', ['==', '_symbol', 4], ['!in', 'Viz', 3]],
			id: 'Road/Major, ramp or traffic circle/1',
			layout: { 'line-cap': 'round', 'line-join': 'round' },
			minzoom: 9,
			paint: {
				'line-color': '#2A2B2B',
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[9, 1.5],
						[14, 7.3],
						[16, 10.3],
						[18, 18],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Road',
			type: 'line',
		},
		{
			filter: ['all', ['==', '_symbol', 3], ['!in', 'Viz', 3]],
			id: 'Road/Major/1',
			layout: { 'line-cap': 'round', 'line-join': 'round' },
			minzoom: 9,
			paint: {
				'line-color': '#2A2B2B',
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[9, 1.5],
						[14, 7.3],
						[16, 10.3],
						[18, 18],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Road',
			type: 'line',
		},
		{
			filter: ['all', ['==', '_symbol', 2], ['!in', 'Viz', 3]],
			id: 'Road/Freeway Motorway, ramp or traffic circle/1',
			layout: { 'line-cap': 'round', 'line-join': 'round' },
			minzoom: 7,
			paint: {
				'line-color': '#2A2B2B',
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[9, 0.3],
						[14, 8.3],
						[16, 12.3],
						[18, 26],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Road',
			type: 'line',
		},
		{
			filter: ['all', ['==', '_symbol', 1], ['!in', 'Viz', 3]],
			id: 'Road/Highway/1',
			layout: { 'line-join': 'round' },
			minzoom: 8,
			paint: {
				'line-color': '#2A2B2B',
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[8, 0.3],
						[14, 8.3],
						[16, 12.3],
						[18, 26],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Road',
			type: 'line',
		},
		{
			filter: ['all', ['==', '_symbol', 0], ['!in', 'Viz', 3]],
			id: 'Road/Freeway Motorway/1',
			layout: { 'line-join': 'round' },
			minzoom: 7,
			paint: {
				'line-color': '#2A2B2B',
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[5, 0.3],
						[14, 8.3],
						[16, 12.3],
						[18, 26],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Road',
			type: 'line',
		},
		{
			id: 'Trail or path/0',
			layout: { 'line-cap': 'round', 'line-join': 'round' },
			minzoom: 15,
			paint: {
				'line-color': '#434444',
				'line-dasharray': [3, 1.5],
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[14, 1.3],
						[16, 2],
						[18, 2.3],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Trail or path',
			type: 'line',
		},
		{
			filter: ['all', ['==', '_symbol', 9], ['!in', 'Viz', 3]],
			id: 'Road/Pedestrian/0',
			layout: { 'line-cap': 'round', 'line-join': 'round' },
			minzoom: 15,
			paint: {
				'line-color': '#434444',
				'line-dasharray': [3, 1.5],
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[14, 1.3],
						[16, 2],
						[18, 2.3],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Road',
			type: 'line',
		},
		{
			filter: ['all', ['==', '_symbol', 10], ['!in', 'Viz', 3]],
			id: 'Road/4WD/0',
			layout: { 'line-join': 'round' },
			minzoom: 13,
			paint: {
				'line-color': '#434444',
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[11, 0.75],
						[14, 1.3],
						[18, 6.3],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Road',
			type: 'line',
		},
		{
			filter: ['all', ['==', '_symbol', 8], ['!in', 'Viz', 3]],
			id: 'Road/Service/0',
			layout: { 'line-cap': 'round', 'line-join': 'round' },
			minzoom: 13,
			paint: {
				'line-color': '#434444',
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[11, 0.75],
						[14, 1.3],
						[18, 6.3],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Road',
			type: 'line',
		},
		{
			filter: ['all', ['==', '_symbol', 7], ['!in', 'Viz', 3]],
			id: 'Road/Local/0',
			layout: { 'line-cap': 'round', 'line-join': 'round' },
			minzoom: 12,
			paint: {
				'line-color': <
					DataDrivenPropertyValueSpecification<ColorSpecification>
				>{
					stops: [
						[12, '#414242'],
						[13, '#434444'],
					],
				},
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[11, 1.1],
						[14, 2],
						[16, 4],
						[18, 15.3],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Road',
			type: 'line',
		},
		{
			filter: ['all', ['==', '_symbol', 6], ['!in', 'Viz', 3]],
			id: 'Road/Minor, ramp or traffic circle/0',
			layout: { 'line-cap': 'round', 'line-join': 'round' },
			minzoom: 11,
			paint: {
				'line-color': '#434444',
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[11, 0.75],
						[14, 2],
						[16, 7.65],
						[18, 15.3],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Road',
			type: 'line',
		},
		{
			filter: ['all', ['==', '_symbol', 5], ['!in', 'Viz', 3]],
			id: 'Road/Minor/0',
			layout: { 'line-cap': 'round', 'line-join': 'round' },
			minzoom: 11,
			paint: {
				'line-color': '#434444',
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[11, 1.3],
						[14, 3.65],
						[16, 7.65],
						[18, 15.3],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Road',
			type: 'line',
		},
		{
			filter: ['all', ['==', '_symbol', 4], ['!in', 'Viz', 3]],
			id: 'Road/Major, ramp or traffic circle/0',
			layout: { 'line-cap': 'round', 'line-join': 'round' },
			minzoom: 9,
			paint: {
				'line-color': '#434444',
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[9, 0.75],
						[14, 5.3],
						[16, 8.3],
						[18, 16],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Road',
			type: 'line',
		},
		{
			filter: ['all', ['==', '_symbol', 3], ['!in', 'Viz', 3]],
			id: 'Road/Major/0',
			layout: { 'line-cap': 'round', 'line-join': 'round' },
			minzoom: 9,
			paint: {
				'line-color': '#434444',
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[9, 0.75],
						[14, 5.3],
						[16, 8.3],
						[18, 16],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Road',
			type: 'line',
		},
		{
			filter: ['all', ['==', '_symbol', 2], ['!in', 'Viz', 3]],
			id: 'Road/Freeway Motorway, ramp or traffic circle/0',
			layout: { 'line-cap': 'round', 'line-join': 'round' },
			minzoom: 7,
			paint: {
				'line-color': '#434444',
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[9, 0.3],
						[14, 6.3],
						[16, 10.3],
						[18, 24],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Road',
			type: 'line',
		},
		{
			filter: ['all', ['==', '_symbol', 1], ['!in', 'Viz', 3]],
			id: 'Road/Highway/0',
			layout: { 'line-join': 'round' },
			minzoom: 8,
			paint: {
				'line-color': '#434444',
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[8, 0.3],
						[14, 6.3],
						[16, 10.3],
						[18, 24],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Road',
			type: 'line',
		},
		{
			filter: ['all', ['==', '_symbol', 0], ['!in', 'Viz', 3]],
			id: 'Road/Freeway Motorway/0',
			layout: { 'line-join': 'round' },
			minzoom: 6,
			paint: {
				'line-color': <
					DataDrivenPropertyValueSpecification<ColorSpecification>
				>{
					stops: [
						[6, '#414242'],
						[7, '#434444'],
					],
				},
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[5, 0.3],
						[14, 6.3],
						[16, 10.3],
						[18, 24],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Road',
			type: 'line',
		},
		{
			filter: ['all', ['==', '_symbol', 10], ['!in', 'Viz', 3]],
			id: 'Road tunnel/4WD/1',
			layout: { 'line-join': 'round' },
			minzoom: 13,
			paint: {
				'line-color': '#2A2B2B',
				'line-dasharray': [2, 1],
				'line-opacity': 0.5,
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[11, 1.5],
						[14, 3.3],
						[18, 8.3],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Road tunnel',
			type: 'line',
		},
		{
			filter: ['all', ['==', '_symbol', 8], ['!in', 'Viz', 3]],
			id: 'Road tunnel/Service/1',
			layout: { 'line-join': 'round' },
			minzoom: 13,
			paint: {
				'line-color': '#2A2B2B',
				'line-opacity': 0.5,
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[11, 1.5],
						[14, 3.3],
						[18, 8.3],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Road tunnel',
			type: 'line',
		},
		{
			filter: ['all', ['==', '_symbol', 7], ['!in', 'Viz', 3]],
			id: 'Road tunnel/Local/1',
			layout: { 'line-join': 'round' },
			minzoom: 12,
			paint: {
				'line-color': '#2A2B2B',
				'line-opacity': 0.5,
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[11, 1.5],
						[14, 4],
						[16, 6],
						[18, 17.3],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Road tunnel',
			type: 'line',
		},
		{
			filter: ['all', ['==', '_symbol', 9], ['!in', 'Viz', 3]],
			id: 'Road tunnel/Pedestrian/1',
			layout: { 'line-join': 'round' },
			minzoom: 15,
			paint: {
				'line-color': '#2A2B2B',
				'line-opacity': 0.5,
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[14, 1.5],
						[16, 3.3],
						[18, 4],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Road tunnel',
			type: 'line',
		},
		{
			filter: ['all', ['==', '_symbol', 6], ['!in', 'Viz', 3]],
			id: 'Road tunnel/Minor, ramp or traffic circle/1',
			layout: { 'line-join': 'round' },
			minzoom: 11,
			paint: {
				'line-color': '#2A2B2B',
				'line-opacity': 0.5,
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[11, 1],
						[14, 4],
						[16, 9.65],
						[18, 17.3],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Road tunnel',
			type: 'line',
		},
		{
			filter: ['all', ['==', '_symbol', 5], ['!in', 'Viz', 3]],
			id: 'Road tunnel/Minor/1',
			layout: { 'line-join': 'round' },
			minzoom: 11,
			paint: {
				'line-color': '#2A2B2B',
				'line-opacity': 0.5,
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[11, 2.6],
						[14, 5.65],
						[16, 9.65],
						[18, 17.3],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Road tunnel',
			type: 'line',
		},
		{
			filter: ['all', ['==', '_symbol', 4], ['!in', 'Viz', 3]],
			id: 'Road tunnel/Major, ramp or traffic circle/1',
			layout: { 'line-join': 'round' },
			minzoom: 9,
			paint: {
				'line-color': '#2A2B2B',
				'line-opacity': 0.5,
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[9, 1.5],
						[14, 7.3],
						[16, 10.3],
						[18, 18],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Road tunnel',
			type: 'line',
		},
		{
			filter: ['all', ['==', '_symbol', 3], ['!in', 'Viz', 3]],
			id: 'Road tunnel/Major/1',
			layout: { 'line-join': 'round' },
			minzoom: 9,
			paint: {
				'line-color': '#2A2B2B',
				'line-opacity': 0.5,
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[9, 1.5],
						[14, 7.3],
						[16, 10.3],
						[18, 18],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Road tunnel',
			type: 'line',
		},
		{
			filter: ['all', ['==', '_symbol', 2], ['!in', 'Viz', 3]],
			id: 'Road tunnel/Freeway Motorway, ramp or traffic circle/1',
			layout: { 'line-join': 'round' },
			minzoom: 7,
			paint: {
				'line-color': '#2A2B2B',
				'line-opacity': 0.5,
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[9, 0.3],
						[14, 8.3],
						[16, 14.3],
						[18, 28],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Road tunnel',
			type: 'line',
		},
		{
			filter: ['all', ['==', '_symbol', 1], ['!in', 'Viz', 3]],
			id: 'Road tunnel/Highway/1',
			layout: { 'line-join': 'round' },
			minzoom: 8,
			paint: {
				'line-color': '#2A2B2B',
				'line-opacity': 0.5,
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[8, 0.3],
						[14, 8.3],
						[16, 14.3],
						[18, 28],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Road tunnel',
			type: 'line',
		},
		{
			filter: ['all', ['==', '_symbol', 0], ['!in', 'Viz', 3]],
			id: 'Road tunnel/Freeway Motorway/1',
			layout: { 'line-join': 'round' },
			minzoom: 7,
			paint: {
				'line-color': '#2A2B2B',
				'line-opacity': 0.5,
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[5, 0.3],
						[14, 8.3],
						[16, 14.3],
						[18, 28],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Road tunnel',
			type: 'line',
		},
		{
			filter: ['all', ['==', '_symbol', 9], ['!in', 'Viz', 3]],
			id: 'Road tunnel/Pedestrian/0',
			layout: { 'line-join': 'round' },
			minzoom: 15,
			paint: {
				'line-color': '#434444',
				'line-dasharray': [3, 1.5],
				'line-opacity': 0.5,
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[14, 1.3],
						[16, 2],
						[18, 2.3],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Road tunnel',
			type: 'line',
		},
		{
			filter: ['all', ['==', '_symbol', 10], ['!in', 'Viz', 3]],
			id: 'Road tunnel/4WD/0',
			layout: { 'line-join': 'round' },
			minzoom: 13,
			paint: {
				'line-color': '#434444',
				'line-opacity': 0.5,
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[11, 0.75],
						[14, 1.3],
						[18, 6.3],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Road tunnel',
			type: 'line',
		},
		{
			filter: ['all', ['==', '_symbol', 8], ['!in', 'Viz', 3]],
			id: 'Road tunnel/Service/0',
			layout: { 'line-join': 'round' },
			minzoom: 13,
			paint: {
				'line-color': '#434444',
				'line-opacity': 0.5,
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[11, 0.75],
						[14, 1.3],
						[18, 6.3],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Road tunnel',
			type: 'line',
		},
		{
			filter: ['all', ['==', '_symbol', 7], ['!in', 'Viz', 3]],
			id: 'Road tunnel/Local/0',
			layout: { 'line-join': 'round' },
			minzoom: 12,
			paint: {
				'line-color': <
					DataDrivenPropertyValueSpecification<ColorSpecification>
				>{
					stops: [
						[12, '#414242'],
						[13, '#434444'],
					],
				},
				'line-opacity': 0.5,
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[11, 1.1],
						[14, 2],
						[16, 4],
						[18, 15.3],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Road tunnel',
			type: 'line',
		},
		{
			filter: ['all', ['==', '_symbol', 6], ['!in', 'Viz', 3]],
			id: 'Road tunnel/Minor, ramp or traffic circle/0',
			layout: { 'line-join': 'round' },
			minzoom: 11,
			paint: {
				'line-color': '#434444',
				'line-opacity': 0.5,
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[11, 0.75],
						[14, 2],
						[16, 7.65],
						[18, 15.3],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Road tunnel',
			type: 'line',
		},
		{
			filter: ['all', ['==', '_symbol', 5], ['!in', 'Viz', 3]],
			id: 'Road tunnel/Minor/0',
			layout: { 'line-join': 'round' },
			minzoom: 11,
			paint: {
				'line-color': '#434444',
				'line-opacity': 0.5,
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[11, 1.3],
						[14, 3.65],
						[16, 7.65],
						[18, 15.3],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Road tunnel',
			type: 'line',
		},
		{
			filter: ['all', ['==', '_symbol', 4], ['!in', 'Viz', 3]],
			id: 'Road tunnel/Major, ramp or traffic circle/0',
			layout: { 'line-join': 'round' },
			minzoom: 9,
			paint: {
				'line-color': '#434444',
				'line-opacity': 0.5,
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[9, 0.75],
						[14, 5.3],
						[16, 8.3],
						[18, 16],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Road tunnel',
			type: 'line',
		},
		{
			filter: ['all', ['==', '_symbol', 3], ['!in', 'Viz', 3]],
			id: 'Road tunnel/Major/0',
			layout: { 'line-join': 'round' },
			minzoom: 9,
			paint: {
				'line-color': '#434444',
				'line-opacity': 0.5,
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[9, 0.75],
						[14, 5.3],
						[16, 8.3],
						[18, 16],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Road tunnel',
			type: 'line',
		},
		{
			filter: ['all', ['==', '_symbol', 2], ['!in', 'Viz', 3]],
			id: 'Road tunnel/Freeway Motorway, ramp or traffic circle/0',
			layout: { 'line-join': 'round' },
			minzoom: 7,
			paint: {
				'line-color': '#434444',
				'line-opacity': 0.5,
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[9, 0.3],
						[14, 6.3],
						[16, 12.3],
						[18, 26],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Road tunnel',
			type: 'line',
		},
		{
			filter: ['all', ['==', '_symbol', 1], ['!in', 'Viz', 3]],
			id: 'Road tunnel/Highway/0',
			layout: { 'line-join': 'round' },
			minzoom: 8,
			paint: {
				'line-color': '#434444',
				'line-opacity': 0.5,
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[8, 0.3],
						[14, 6.3],
						[16, 12.3],
						[18, 26],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Road tunnel',
			type: 'line',
		},
		{
			filter: ['all', ['==', '_symbol', 0], ['!in', 'Viz', 3]],
			id: 'Road tunnel/Freeway Motorway/0',
			layout: { 'line-join': 'round' },
			minzoom: 6,
			paint: {
				'line-color': <
					DataDrivenPropertyValueSpecification<ColorSpecification>
				>{
					stops: [
						[6, '#414242'],
						[7, '#434444'],
					],
				},
				'line-opacity': 0.5,
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[5, 0.3],
						[14, 6.3],
						[16, 12.3],
						[18, 26],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Road tunnel',
			type: 'line',
		},
		{
			filter: ['==', '_symbol', 9],
			id: 'Special area of interest/Gutter',
			layout: {},
			minzoom: 14,
			paint: { 'fill-color': '#353636' },
			source: 'esri',
			'source-layer': 'Special area of interest',
			type: 'fill',
		},
		{
			filter: ['==', '_symbol', 3],
			id: 'Special area of interest/Curb',
			layout: {},
			minzoom: 14,
			paint: { 'fill-color': '#353636', 'fill-outline-color': '#2d2e2e' },
			source: 'esri',
			'source-layer': 'Special area of interest',
			type: 'fill',
		},
		{
			filter: ['all', ['==', '_symbol', 8], ['!in', 'Viz', 3]],
			id: 'Boundary line/Disputed admin2/1',
			layout: { 'line-join': 'round' },
			minzoom: 9,
			paint: {
				'line-color': '#414242',
				'line-opacity': 0.95,
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[4, 0.65],
						[14, 7],
						[17, 7],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Boundary line',
			type: 'line',
		},
		{
			filter: ['all', ['==', '_symbol', 8], ['!in', 'Viz', 3]],
			id: 'Boundary line/Disputed admin2/0',
			layout: { 'line-join': 'round' },
			minzoom: 9,
			paint: {
				'line-color': '#2D2E2E',
				'line-dasharray': [7, 5],
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[1, 0.65],
						[14, 1.3],
						[17, 2.65],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Boundary line',
			type: 'line',
		},
		{
			filter: ['all', ['==', '_symbol', 7], ['!in', 'Viz', 3]],
			id: 'Boundary line/Disputed admin1/1',
			layout: { 'line-join': 'round' },
			minzoom: 4,
			paint: {
				'line-color': '#434444',
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[4, 0.65],
						[14, 7],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Boundary line',
			type: 'line',
		},
		{
			filter: [
				'all',
				['==', '_symbol', 6],
				['!in', 'Viz', 3],
				['!in', 'DisputeID', 8, 16, 90, 96, 0],
			],
			id: 'Boundary line/Disputed admin0/1',
			layout: { 'line-join': 'round' },
			minzoom: 1,
			paint: {
				'line-color': '#434444',
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[1, 0.65],
						[14, 9.3],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Boundary line',
			type: 'line',
		},
		{
			filter: ['all', ['==', '_symbol', 7], ['!in', 'Viz', 3]],
			id: 'Boundary line/Disputed admin1/0',
			layout: { 'line-join': 'round' },
			minzoom: 4,
			paint: {
				'line-color': '#2D2E2E',
				'line-dasharray': [6, 5],
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[1, 0.65],
						[14, 1.3],
						[17, 2.65],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Boundary line',
			type: 'line',
		},
		{
			filter: [
				'all',
				['==', '_symbol', 6],
				['!in', 'Viz', 3],
				['!in', 'DisputeID', 8, 16, 90, 96, 0],
			],
			id: 'Boundary line/Disputed admin0/0',
			layout: { 'line-join': 'round' },
			minzoom: 1,
			paint: {
				'line-color': '#2D2E2E',
				'line-dasharray': [7, 5],
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[1, 0.65],
						[14, 1.3],
						[17, 2.65],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Boundary line',
			type: 'line',
		},
		{
			filter: ['all', ['==', '_symbol', 2], ['!in', 'Viz', 3]],
			id: 'Boundary line/Admin2/1',
			layout: { 'line-cap': 'round', 'line-join': 'round' },
			minzoom: 9,
			paint: {
				'line-color': '#2D2E2E',
				'line-opacity': 0.6,
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[8, 3],
						[14, 4.5],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Boundary line',
			type: 'line',
		},
		{
			filter: ['all', ['==', '_symbol', 1], ['!in', 'Viz', 3]],
			id: 'Boundary line/Admin1/1',
			layout: { 'line-cap': 'round', 'line-join': 'round' },
			minzoom: 4,
			paint: {
				'line-color': <
					DataDrivenPropertyValueSpecification<ColorSpecification>
				>{
					stops: [
						[1, '#323333'],
						[12, '#2D2E2E'],
					],
				},
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[4, 0.73],
						[14, 8],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Boundary line',
			type: 'line',
		},
		{
			filter: ['all', ['==', '_symbol', 0], ['!in', 'Viz', 3]],
			id: 'Boundary line/Admin0/1',
			layout: { 'line-cap': 'round', 'line-join': 'round' },
			minzoom: 1,
			paint: {
				'line-color': <
					DataDrivenPropertyValueSpecification<ColorSpecification>
				>{
					stops: [
						[1, '#323333'],
						[12, '#2D2E2E'],
					],
				},
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[1, 0.65],
						[14, 10.5],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Boundary line',
			type: 'line',
		},
		{
			filter: ['all', ['==', '_symbol', 5], ['!in', 'Viz', 3]],
			id: 'Boundary line/Admin5',
			layout: { 'line-cap': 'round', 'line-join': 'round' },
			minzoom: 16,
			paint: {
				'line-color': '#515252',
				'line-dasharray': [5, 3],
				'line-width': 1,
			},
			source: 'esri',
			'source-layer': 'Boundary line',
			type: 'line',
		},
		{
			filter: ['all', ['==', '_symbol', 4], ['!in', 'Viz', 3]],
			id: 'Boundary line/Admin4',
			layout: { 'line-cap': 'round', 'line-join': 'round' },
			minzoom: 16,
			paint: {
				'line-color': '#515252',
				'line-dasharray': [5, 3],
				'line-width': 1,
			},
			source: 'esri',
			'source-layer': 'Boundary line',
			type: 'line',
		},
		{
			filter: ['all', ['==', '_symbol', 3], ['!in', 'Viz', 3]],
			id: 'Boundary line/Admin3',
			layout: { 'line-cap': 'round', 'line-join': 'round' },
			minzoom: 16,
			paint: {
				'line-color': '#515252',
				'line-dasharray': [5, 3],
				'line-width': 1,
			},
			source: 'esri',
			'source-layer': 'Boundary line',
			type: 'line',
		},
		{
			filter: ['all', ['==', '_symbol', 2], ['!in', 'Viz', 3]],
			id: 'Boundary line/Admin2/0',
			layout: { 'line-join': 'round' },
			minzoom: 9,
			paint: {
				'line-color': '#515252',
				'line-dasharray': [6, 4],
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[8, 0.6],
						[14, 1.3],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Boundary line',
			type: 'line',
		},
		{
			filter: ['all', ['==', '_symbol', 1], ['!in', 'Viz', 3]],
			id: 'Boundary line/Admin1/0',
			layout: { 'line-cap': 'round', 'line-join': 'round' },
			minzoom: 7,
			paint: {
				'line-color': <
					DataDrivenPropertyValueSpecification<ColorSpecification>
				>{
					stops: [
						[7, '#3F4040'],
						[12, '#444545'],
					],
				},
				'line-dasharray': [7, 5.3],
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[7, 0.3],
						[14, 1.3],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Boundary line',
			type: 'line',
		},
		{
			filter: ['all', ['==', '_symbol', 0], ['!in', 'Viz', 3]],
			id: 'Boundary line/Admin0/0',
			layout: { 'line-cap': 'round', 'line-join': 'round' },
			minzoom: 5,
			paint: {
				'line-color': <
					DataDrivenPropertyValueSpecification<ColorSpecification>
				>{
					stops: [
						[5, '#383939'],
						[7, '#4A4C4C'],
					],
				},
				'line-dasharray': [7, 5.3],
				'line-width': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[5, 0.7],
						[14, 1.3],
					],
				},
			},
			source: 'esri',
			'source-layer': 'Boundary line',
			type: 'line',
		},
		{
			filter: ['==', '_label_class', 0],
			id: 'Water point/Sea or ocean',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-anchor': 'center',
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Medium Italic'],
				'text-letter-spacing': 0.3,
				'text-line-height': 1.6,
				'text-max-width': 4,
				'text-padding': 15,
				'text-size': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[9, 8.5],
						[15, 15.5],
					],
				},
			},
			minzoom: 9,
			paint: {
				'text-color': '#707374',
				'text-halo-blur': 1,
				'text-halo-color': 'rgba(12, 36, 56, 1)',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Water point',
			type: 'symbol',
		},
		{
			filter: ['==', '_label_class', 7],
			id: 'Water point/Island',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-anchor': 'center',
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Medium Italic'],
				'text-letter-spacing': 0.1,
				'text-max-width': 4,
				'text-padding': 15,
				'text-size': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[9, 8.5],
						[15, 10],
					],
				},
			},
			minzoom: 9,
			paint: {
				'text-color': '#808080',
				'text-halo-blur': 1,
				'text-halo-color': '#323333',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Water point',
			type: 'symbol',
		},
		{
			filter: ['==', '_label_class', 5],
			id: 'Water point/Dam or weir',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-anchor': 'center',
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': 0.1,
				'text-max-width': 4,
				'text-padding': 15,
				'text-size': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[9, 8.5],
						[15, 10],
					],
				},
			},
			minzoom: 9,
			paint: {
				'text-color': '#808080',
				'text-halo-blur': 1,
				'text-halo-color': '#323333',
				'text-halo-width': 0.7,
			},
			source: 'esri',
			'source-layer': 'Water point',
			type: 'symbol',
		},
		{
			filter: ['==', '_label_class', 6],
			id: 'Water point/Playa',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-anchor': 'center',
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Medium Italic'],
				'text-letter-spacing': 0.1,
				'text-max-width': 4,
				'text-padding': 15,
				'text-size': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[9, 8.5],
						[15, 10],
					],
				},
			},
			minzoom: 9,
			paint: {
				'text-color': '#808080',
				'text-halo-blur': 1,
				'text-halo-color': '#323333',
				'text-halo-width': 0.7,
			},
			source: 'esri',
			'source-layer': 'Water point',
			type: 'symbol',
		},
		{
			filter: ['==', '_label_class', 4],
			id: 'Water point/Canal or ditch',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-anchor': 'center',
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Medium Italic'],
				'text-letter-spacing': 0.13,
				'text-max-width': 4,
				'text-padding': 15,
				'text-size': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[9, 8.5],
						[15, 10],
					],
				},
			},
			minzoom: 9,
			paint: {
				'text-color': '#707374',
				'text-halo-blur': 1,
				'text-halo-color': 'rgba(12, 36, 56, 1)',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Water point',
			type: 'symbol',
		},
		{
			filter: ['==', '_label_class', 3],
			id: 'Water point/Stream or river',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-anchor': 'center',
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Medium Italic'],
				'text-letter-spacing': 0.1,
				'text-max-width': 4,
				'text-padding': 15,
				'text-size': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[9, 8.5],
						[15, 10],
					],
				},
			},
			minzoom: 9,
			paint: {
				'text-color': '#707374',
				'text-halo-blur': 1,
				'text-halo-color': 'rgba(12, 36, 56, 1)',
				'text-halo-width': 0.5,
			},
			source: 'esri',
			'source-layer': 'Water point',
			type: 'symbol',
		},
		{
			filter: ['==', '_label_class', 2],
			id: 'Water point/Lake or reservoir',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-anchor': 'center',
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Medium Italic'],
				'text-letter-spacing': 0.1,
				'text-max-width': 4,
				'text-padding': 15,
				'text-size': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[9, 8.5],
						[15, 10],
					],
				},
			},
			minzoom: 9,
			paint: {
				'text-color': '#707374',
				'text-halo-blur': 1,
				'text-halo-color': 'rgba(12, 36, 56, 1)',
				'text-halo-width': 0.5,
			},
			source: 'esri',
			'source-layer': 'Water point',
			type: 'symbol',
		},
		{
			filter: ['==', '_label_class', 1],
			id: 'Water point/Bay or inlet',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-anchor': 'center',
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Medium Italic'],
				'text-letter-spacing': 0.1,
				'text-max-width': 4,
				'text-padding': 15,
				'text-size': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[9, 8.5],
						[15, 10],
					],
				},
			},
			minzoom: 9,
			paint: {
				'text-color': '#707374',
				'text-halo-blur': 1,
				'text-halo-color': 'rgba(12, 36, 56, 1)',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Water point',
			type: 'symbol',
		},
		{
			id: 'Water line/label/Default',
			layout: {
				'symbol-avoid-edges': true,
				'symbol-placement': 'line',
				'symbol-spacing': 800,
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Italic'],
				'text-letter-spacing': 0.07,
				'text-max-angle': 18,
				'text-max-width': 6,
				'text-offset': [0, -0.5],
				'text-padding': 1,
				'text-size': 9,
			},
			minzoom: 11,
			paint: {
				'text-color': '#707374',
				'text-halo-blur': 1,
				'text-halo-color': 'rgba(12, 36, 56, 1)',
				'text-halo-width': 0.5,
			},
			source: 'esri',
			'source-layer': 'Water line/label',
			type: 'symbol',
		},
		{
			id: 'Marine park/label/Default',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': 0.05,
				'text-max-width': 6,
				'text-padding': 15,
				'text-size': 9.5,
			},
			minzoom: 16,
			paint: { 'text-color': '#707374' },
			source: 'esri',
			'source-layer': 'Marine park/label',
			type: 'symbol',
		},
		{
			filter: ['==', '_label_class', 8],
			id: 'Water area/label/Dam or weir',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': 0.08,
				'text-max-width': 4,
				'text-padding': 15,
				'text-size': 9.5,
			},
			minzoom: 11,
			paint: {
				'text-color': '#808080',
				'text-halo-blur': 1,
				'text-halo-color': '#323333',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Water area/label',
			type: 'symbol',
		},
		{
			filter: ['==', '_label_class', 9],
			id: 'Water area/label/Playa',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Medium Italic'],
				'text-letter-spacing': 0.08,
				'text-max-width': 4,
				'text-padding': 15,
				'text-size': 9.5,
			},
			minzoom: 11,
			paint: {
				'text-color': '#808080',
				'text-halo-blur': 1,
				'text-halo-color': '#323333',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Water area/label',
			type: 'symbol',
		},
		{
			filter: ['==', '_label_class', 2],
			id: 'Water area/label/Canal or ditch',
			layout: {
				'symbol-avoid-edges': true,
				'symbol-placement': 'line',
				'symbol-spacing': 1000,
				'text-allow-overlap': false,
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Medium Italic'],
				'text-letter-spacing': 0.13,
				'text-max-width': 5,
				'text-padding': 15,
				'text-size': 9.5,
			},
			minzoom: 11,
			paint: {
				'text-color': '#707374',
				'text-halo-blur': 1,
				'text-halo-color': 'rgba(12, 36, 56, 1)',
				'text-halo-width': 0.5,
			},
			source: 'esri',
			'source-layer': 'Water area/label',
			type: 'symbol',
		},
		{
			filter: ['==', '_label_class', 7],
			id: 'Water area/label/Small river',
			layout: {
				'symbol-avoid-edges': true,
				'symbol-placement': 'line',
				'symbol-spacing': 1000,
				'text-allow-overlap': false,
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Medium Italic'],
				'text-letter-spacing': 0.13,
				'text-max-width': 8,
				'text-padding': 15,
				'text-size': 9.5,
			},
			minzoom: 11,
			paint: {
				'text-color': '#707374',
				'text-halo-blur': 1,
				'text-halo-color': 'rgba(12, 36, 56, 1)',
				'text-halo-width': 0.5,
			},
			source: 'esri',
			'source-layer': 'Water area/label',
			type: 'symbol',
		},
		{
			filter: ['==', '_label_class', 4],
			id: 'Water area/label/Large river',
			layout: {
				'symbol-avoid-edges': true,
				'symbol-placement': 'line',
				'symbol-spacing': 1000,
				'text-allow-overlap': false,
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Medium Italic'],
				'text-letter-spacing': 0.13,
				'text-max-width': 8,
				'text-padding': 15,
				'text-size': 10,
			},
			minzoom: 11,
			paint: {
				'text-color': '#707374',
				'text-halo-blur': 1,
				'text-halo-color': 'rgba(12, 36, 56, 1)',
				'text-halo-width': 0.5,
			},
			source: 'esri',
			'source-layer': 'Water area/label',
			type: 'symbol',
		},
		{
			filter: ['==', '_label_class', 6],
			id: 'Water area/label/Small lake or reservoir',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Medium Italic'],
				'text-letter-spacing': 0.13,
				'text-max-width': 4,
				'text-padding': 15,
				'text-size': 9.5,
			},
			minzoom: 11,
			paint: {
				'text-color': '#707374',
				'text-halo-blur': 1,
				'text-halo-color': 'rgba(12, 36, 56, 1)',
				'text-halo-width': 0.5,
			},
			source: 'esri',
			'source-layer': 'Water area/label',
			type: 'symbol',
		},
		{
			filter: ['==', '_label_class', 3],
			id: 'Water area/label/Large lake or reservoir',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Medium Italic'],
				'text-letter-spacing': 0.13,
				'text-line-height': 1.15,
				'text-max-width': 4,
				'text-padding': 15,
				'text-size': 10,
			},
			minzoom: 11,
			paint: {
				'text-color': '#707374',
				'text-halo-blur': 1,
				'text-halo-color': 'rgba(12, 36, 56, 1)',
				'text-halo-width': 0.5,
			},
			source: 'esri',
			'source-layer': 'Water area/label',
			type: 'symbol',
		},
		{
			filter: ['==', '_label_class', 1],
			id: 'Water area/label/Bay or inlet',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Medium Italic'],
				'text-letter-spacing': 0.13,
				'text-line-height': 1.15,
				'text-max-width': 4,
				'text-padding': 15,
				'text-size': 11,
			},
			minzoom: 11,
			paint: {
				'text-color': '#707374',
				'text-halo-blur': 1,
				'text-halo-color': 'rgba(12, 36, 56, 1)',
				'text-halo-width': 0.5,
			},
			source: 'esri',
			'source-layer': 'Water area/label',
			type: 'symbol',
		},
		{
			filter: ['==', '_label_class', 0],
			id: 'Water area/label/Small island',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Italic'],
				'text-letter-spacing': 0.1,
				'text-max-width': 4,
				'text-padding': 15,
				'text-size': 9.5,
			},
			minzoom: 11,
			paint: {
				'text-color': '#808080',
				'text-halo-blur': 1,
				'text-halo-color': '#323333',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Water area/label',
			type: 'symbol',
		},
		{
			filter: ['==', '_label_class', 5],
			id: 'Water area/label/Large island',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Italic'],
				'text-letter-spacing': 0.13,
				'text-max-width': 4,
				'text-padding': 15,
				'text-size': 10,
			},
			minzoom: 11,
			paint: {
				'text-color': '#808080',
				'text-halo-blur': 1,
				'text-halo-color': '#323333',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Water area/label',
			type: 'symbol',
		},
		{
			filter: ['==', '_label_class', 1],
			id: 'Water area large scale/label/River',
			layout: {
				'symbol-avoid-edges': true,
				'symbol-placement': 'line',
				'symbol-spacing': 1000,
				'text-allow-overlap': false,
				'text-field': '{_name}',
				'text-font': ['Ubuntu Medium Italic'],
				'text-letter-spacing': 0.1,
				'text-max-width': 4,
				'text-padding': 15,
				'text-size': 9,
			},
			maxzoom: 11,
			minzoom: 7,
			paint: {
				'text-color': '#707374',
				'text-halo-blur': 1,
				'text-halo-color': 'rgba(12, 36, 56, 1)',
				'text-halo-width': 0.5,
			},
			source: 'esri',
			'source-layer': 'Water area large scale/label',
			type: 'symbol',
		},
		{
			filter: ['==', '_label_class', 0],
			id: 'Water area large scale/label/Lake or lake intermittent',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name}',
				'text-font': ['Ubuntu Medium Italic'],
				'text-letter-spacing': 0.1,
				'text-max-width': 4,
				'text-padding': 15,
				'text-size': 9.5,
			},
			maxzoom: 11,
			minzoom: 7,
			paint: {
				'text-color': '#707374',
				'text-halo-blur': 1,
				'text-halo-color': 'rgba(12, 36, 56, 1)',
				'text-halo-width': 0.5,
			},
			source: 'esri',
			'source-layer': 'Water area large scale/label',
			type: 'symbol',
		},
		{
			id: 'Water area medium scale/label/Default',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name}',
				'text-font': ['Ubuntu Medium Italic'],
				'text-letter-spacing': 0.08,
				'text-max-width': 4,
				'text-padding': 15,
				'text-size': 9,
			},
			maxzoom: 7,
			minzoom: 5,
			paint: {
				'text-color': '#707374',
				'text-halo-blur': 1,
				'text-halo-color': 'rgba(12, 36, 56, 1)',
				'text-halo-width': 0.5,
			},
			source: 'esri',
			'source-layer': 'Water area medium scale/label',
			type: 'symbol',
		},
		{
			id: 'Water area small scale/label/Default',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name}',
				'text-font': ['Ubuntu Medium Italic'],
				'text-letter-spacing': 0.08,
				'text-max-width': 4,
				'text-padding': 15,
				'text-size': 9,
			},
			maxzoom: 5,
			minzoom: 1,
			paint: {
				'text-color': '#707374',
				'text-halo-blur': 1,
				'text-halo-color': 'rgba(12, 36, 56, 1)',
				'text-halo-width': 0.5,
			},
			source: 'esri',
			'source-layer': 'Water area small scale/label',
			type: 'symbol',
		},
		{
			id: 'Marine area/label/Default',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Medium Italic'],
				'text-letter-spacing': 0.13,
				'text-max-width': 4,
				'text-padding': 15,
				'text-size': 10,
			},
			minzoom: 11,
			paint: {
				'text-color': '#707374',
				'text-halo-blur': 1,
				'text-halo-color': 'rgba(12, 36, 56, 1)',
				'text-halo-width': 0.5,
			},
			source: 'esri',
			'source-layer': 'Marine area/label',
			type: 'symbol',
		},
		{
			filter: ['==', '_label_class', 4],
			id: 'Marine waterbody/label/small',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name}',
				'text-font': ['Ubuntu Medium Italic'],
				'text-letter-spacing': 0.12,
				'text-line-height': 1.2,
				'text-max-width': 4,
				'text-padding': 15,
				'text-size': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[1, 9],
						[6, 11],
					],
				},
			},
			maxzoom: 10,
			minzoom: 4,
			paint: {
				'text-color': '#707374',
				'text-halo-blur': 1,
				'text-halo-color': 'rgba(12, 36, 56, 1)',
				'text-halo-width': 0.5,
			},
			source: 'esri',
			'source-layer': 'Marine waterbody/label',
			type: 'symbol',
		},
		{
			filter: ['==', '_label_class', 3],
			id: 'Marine waterbody/label/medium',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name}',
				'text-font': ['Ubuntu Medium Italic'],
				'text-letter-spacing': 0.15,
				'text-line-height': 1.2,
				'text-max-width': 4,
				'text-padding': 15,
				'text-size': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[1, 9],
						[6, 11],
					],
				},
			},
			maxzoom: 10,
			minzoom: 4,
			paint: {
				'text-color': '#707374',
				'text-halo-blur': 1,
				'text-halo-color': 'rgba(12, 36, 56, 1)',
				'text-halo-width': 0.5,
			},
			source: 'esri',
			'source-layer': 'Marine waterbody/label',
			type: 'symbol',
		},
		{
			filter: ['==', '_label_class', 2],
			id: 'Marine waterbody/label/large',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name}',
				'text-font': ['Ubuntu Medium Italic'],
				'text-letter-spacing': 0.18,
				'text-line-height': 1.5,
				'text-max-width': 4,
				'text-padding': 15,
				'text-size': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[1, 9],
						[6, 12],
					],
				},
			},
			maxzoom: 10,
			minzoom: 4,
			paint: {
				'text-color': '#707374',
				'text-halo-blur': 1,
				'text-halo-color': 'rgba(12, 36, 56, 1)',
				'text-halo-width': 0.5,
			},
			source: 'esri',
			'source-layer': 'Marine waterbody/label',
			type: 'symbol',
		},
		{
			filter: ['==', '_label_class', 1],
			id: 'Marine waterbody/label/x large',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name}',
				'text-font': ['Ubuntu Medium Italic'],
				'text-letter-spacing': 0.2,
				'text-line-height': 1.5,
				'text-max-width': 4,
				'text-padding': 15,
				'text-size': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[1, 10],
						[6, 13],
					],
				},
			},
			maxzoom: 10,
			minzoom: 3,
			paint: {
				'text-color': '#707374',
				'text-halo-blur': 1,
				'text-halo-color': 'rgba(12, 36, 56, 1)',
				'text-halo-width': 0.5,
			},
			source: 'esri',
			'source-layer': 'Marine waterbody/label',
			type: 'symbol',
		},
		{
			filter: ['==', '_label_class', 0],
			id: 'Marine waterbody/label/2x large',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name}',
				'text-font': ['Ubuntu Medium Italic'],
				'text-letter-spacing': 0.3,
				'text-line-height': 1.6,
				'text-max-width': 4,
				'text-padding': 15,
				'text-size': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[1, 11],
						[4, 14],
					],
				},
			},
			maxzoom: 10,
			minzoom: 2,
			paint: {
				'text-color': '#707374',
				'text-halo-blur': 1,
				'text-halo-color': 'rgba(12, 36, 56, 1)',
				'text-halo-width': 0.5,
			},
			source: 'esri',
			'source-layer': 'Marine waterbody/label',
			type: 'symbol',
		},
		{
			filter: ['all', ['==', '_label_class', 1], ['!in', 'Viz', 3]],
			id: 'Ferry/label/Rail ferry',
			layout: {
				'symbol-avoid-edges': true,
				'symbol-placement': 'line',
				'symbol-spacing': 1000,
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': 0.1,
				'text-max-width': 6,
				'text-offset': [0, -0.6],
				'text-padding': 5,
				'text-size': 8.5,
			},
			minzoom: 16,
			paint: {
				'text-color': '#808080',
				'text-halo-color': '#323333',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Ferry/label',
			type: 'symbol',
		},
		{
			id: 'Railroad/label/Default',
			layout: {
				'symbol-avoid-edges': true,
				'symbol-placement': 'line',
				'symbol-spacing': 1000,
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': 0.1,
				'text-max-width': 6,
				'text-offset': [0, -0.6],
				'text-padding': 5,
				'text-size': 8.5,
			},
			minzoom: 16,
			paint: {
				'text-color': '#808080',
				'text-halo-color': '#323333',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Railroad/label',
			type: 'symbol',
		},
		{
			filter: ['all', ['==', '_label_class', 6], ['!in', 'Viz', 3]],
			id: 'Road tunnel/label/Pedestrian',
			layout: {
				'symbol-avoid-edges': true,
				'symbol-placement': 'line',
				'symbol-spacing': 400,
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': 0.05,
				'text-max-width': 6,
				'text-padding': 5,
				'text-size': 9.3,
			},
			minzoom: 15,
			paint: {
				'text-color': '#a3a3a1',
				'text-halo-blur': 1,
				'text-halo-color': '#212121',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Road tunnel/label',
			type: 'symbol',
		},
		{
			filter: ['all', ['==', '_label_class', 5], ['!in', 'Viz', 3]],
			id: 'Road tunnel/label/Local, service, 4WD',
			layout: {
				'symbol-avoid-edges': true,
				'symbol-placement': 'line',
				'symbol-spacing': 400,
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': 0.09,
				'text-max-width': 6,
				'text-padding': 5,
				'text-size': 9.5,
			},
			minzoom: 15,
			paint: {
				'text-color': '#a3a3a1',
				'text-halo-blur': 1,
				'text-halo-color': '#212121',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Road tunnel/label',
			type: 'symbol',
		},
		{
			filter: ['all', ['==', '_label_class', 4], ['!in', 'Viz', 3]],
			id: 'Road tunnel/label/Minor',
			layout: {
				'symbol-avoid-edges': true,
				'symbol-placement': 'line',
				'symbol-spacing': 400,
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': 0.09,
				'text-max-width': 6,
				'text-padding': 5,
				'text-size': 9.5,
			},
			minzoom: 14,
			paint: {
				'text-color': '#a3a3a1',
				'text-halo-blur': 1,
				'text-halo-color': '#212121',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Road tunnel/label',
			type: 'symbol',
		},
		{
			filter: ['all', ['==', '_label_class', 3], ['!in', 'Viz', 3]],
			id: 'Road tunnel/label/Major, alt name',
			layout: {
				'symbol-avoid-edges': true,
				'symbol-placement': 'line',
				'symbol-spacing': 400,
				'text-field': '{_name}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': 0.09,
				'text-max-width': 6,
				'text-padding': 5,
				'text-size': 10.5,
			},
			minzoom: 14,
			paint: {
				'text-color': '#a3a3a1',
				'text-halo-blur': 1,
				'text-halo-color': '#212121',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Road tunnel/label',
			type: 'symbol',
		},
		{
			filter: ['all', ['==', '_label_class', 2], ['!in', 'Viz', 3]],
			id: 'Road tunnel/label/Major',
			layout: {
				'symbol-avoid-edges': true,
				'symbol-placement': 'line',
				'symbol-spacing': 400,
				'text-field': '{_name}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': 0.09,
				'text-max-width': 6,
				'text-padding': 5,
				'text-size': 10.5,
			},
			minzoom: 14,
			paint: {
				'text-color': '#a3a3a1',
				'text-halo-blur': 1,
				'text-halo-color': '#212121',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Road tunnel/label',
			type: 'symbol',
		},
		{
			filter: ['all', ['==', '_label_class', 7], ['!in', 'Viz', 3]],
			id: 'Road tunnel/label/Highway',
			layout: {
				'symbol-avoid-edges': true,
				'symbol-placement': 'line',
				'symbol-spacing': 400,
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': 0.09,
				'text-max-width': 6,
				'text-padding': 5,
				'text-size': 10.5,
			},
			minzoom: 13,
			paint: {
				'text-color': '#a3a3a1',
				'text-halo-blur': 1,
				'text-halo-color': '#212121',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Road tunnel/label',
			type: 'symbol',
		},
		{
			filter: ['all', ['==', '_label_class', 1], ['!in', 'Viz', 3]],
			id: 'Road tunnel/label/Freeway Motorway, alt name',
			layout: {
				'symbol-avoid-edges': true,
				'symbol-placement': 'line',
				'symbol-spacing': 400,
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': 0.09,
				'text-max-width': 6,
				'text-padding': 5,
				'text-size': 10.5,
			},
			minzoom: 13,
			paint: {
				'text-color': '#a3a3a1',
				'text-halo-blur': 1,
				'text-halo-color': '#212121',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Road tunnel/label',
			type: 'symbol',
		},
		{
			filter: ['all', ['==', '_label_class', 0], ['!in', 'Viz', 3]],
			id: 'Road tunnel/label/Freeway Motorway',
			layout: {
				'symbol-avoid-edges': true,
				'symbol-placement': 'line',
				'symbol-spacing': 400,
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': 0.09,
				'text-max-width': 6,
				'text-padding': 5,
				'text-size': 10.5,
			},
			minzoom: 13,
			paint: {
				'text-color': '#a3a3a1',
				'text-halo-blur': 1,
				'text-halo-color': '#212121',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Road tunnel/label',
			type: 'symbol',
		},
		{
			id: 'Building/label/Default',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': 0.08,
				'text-max-width': 6,
				'text-padding': 15,
				'text-size': 9.5,
			},
			minzoom: 17,
			paint: {
				'text-color': '#808080',
				'text-halo-blur': 1,
				'text-halo-color': '#212121',
				'text-halo-width': 0.7,
			},
			source: 'esri',
			'source-layer': 'Building/label',
			type: 'symbol',
		},
		{
			id: 'Trail or path/label/Default',
			layout: {
				'symbol-avoid-edges': true,
				'symbol-placement': 'line',
				'symbol-spacing': 400,
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': 0.05,
				'text-max-width': 6,
				'text-padding': 5,
				'text-size': 9.3,
			},
			minzoom: 15,
			paint: {
				'text-color': '#a3a3a1',
				'text-halo-color': '#212121',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Trail or path/label',
			type: 'symbol',
		},
		{
			filter: ['all', ['==', '_label_class', 6], ['!in', 'Viz', 3]],
			id: 'Road/label/Pedestrian',
			layout: {
				'symbol-avoid-edges': true,
				'symbol-placement': 'line',
				'symbol-spacing': 400,
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': 0.05,
				'text-max-width': 6,
				'text-padding': 5,
				'text-size': 9.3,
			},
			minzoom: 15,
			paint: {
				'text-color': '#a3a3a1',
				'text-halo-color': '#212121',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Road/label',
			type: 'symbol',
		},
		{
			filter: ['all', ['==', '_label_class', 5], ['!in', 'Viz', 3]],
			id: 'Road/label/Local',
			layout: {
				'symbol-avoid-edges': true,
				'symbol-placement': 'line',
				'symbol-spacing': 400,
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': 0.09,
				'text-max-width': 6,
				'text-padding': 5,
				'text-size': 9.5,
			},
			minzoom: 15,
			paint: {
				'text-color': '#a3a3a1',
				'text-halo-color': '#212121',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Road/label',
			type: 'symbol',
		},
		{
			filter: ['all', ['==', '_label_class', 4], ['!in', 'Viz', 3]],
			id: 'Road/label/Minor',
			layout: {
				'symbol-avoid-edges': true,
				'symbol-placement': 'line',
				'symbol-spacing': 400,
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': 0.09,
				'text-max-width': 6,
				'text-padding': 2,
				'text-size': 10,
			},
			minzoom: 14,
			paint: {
				'text-color': '#a3a3a1',
				'text-halo-color': '#212121',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Road/label',
			type: 'symbol',
		},
		{
			filter: ['all', ['==', '_label_class', 3], ['!in', 'Viz', 3]],
			id: 'Road/label/Major, alt name',
			layout: {
				'symbol-avoid-edges': true,
				'symbol-placement': 'line',
				'symbol-spacing': 400,
				'text-field': '{_name}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': 0.09,
				'text-max-width': 6,
				'text-padding': 2,
				'text-size': 10.5,
			},
			minzoom: 14,
			paint: {
				'text-color': '#a3a3a1',
				'text-halo-color': '#212121',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Road/label',
			type: 'symbol',
		},
		{
			filter: ['all', ['==', '_label_class', 2], ['!in', 'Viz', 3]],
			id: 'Road/label/Major',
			layout: {
				'symbol-avoid-edges': true,
				'symbol-placement': 'line',
				'symbol-spacing': 400,
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': 0.09,
				'text-max-width': 6,
				'text-padding': 2,
				'text-size': 10.5,
			},
			minzoom: 14,
			paint: {
				'text-color': '#a3a3a1',
				'text-halo-color': '#212121',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Road/label',
			type: 'symbol',
		},
		{
			filter: ['all', ['==', '_label_class', 75], ['!in', 'Viz', 3]],
			id: 'Road/label/Highway',
			layout: {
				'symbol-avoid-edges': true,
				'symbol-placement': 'line',
				'symbol-spacing': 400,
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': 0.09,
				'text-max-width': 6,
				'text-padding': 2,
				'text-size': 10.5,
			},
			minzoom: 13,
			paint: {
				'text-color': '#a3a3a1',
				'text-halo-color': '#212121',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Road/label',
			type: 'symbol',
		},
		{
			filter: ['all', ['==', '_label_class', 1], ['!in', 'Viz', 3]],
			id: 'Road/label/Freeway Motorway, alt name',
			layout: {
				'symbol-avoid-edges': true,
				'symbol-placement': 'line',
				'symbol-spacing': 400,
				'text-field': '{_name}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': 0.09,
				'text-max-width': 6,
				'text-padding': 2,
				'text-size': 10.5,
			},
			minzoom: 13,
			paint: {
				'text-color': '#a3a3a1',
				'text-halo-color': '#212121',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Road/label',
			type: 'symbol',
		},
		{
			filter: ['all', ['==', '_label_class', 0], ['!in', 'Viz', 3]],
			id: 'Road/label/Freeway Motorway',
			layout: {
				'symbol-avoid-edges': true,
				'symbol-placement': 'line',
				'symbol-spacing': 400,
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': 0.09,
				'text-max-width': 6,
				'text-padding': 2,
				'text-size': 10.5,
			},
			minzoom: 13,
			paint: {
				'text-color': '#a3a3a1',
				'text-halo-color': '#212121',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Road/label',
			type: 'symbol',
		},
		{
			filter: [
				'all',
				[
					'in',
					'_label_class',
					8,
					10,
					12,
					14,
					16,
					18,
					20,
					22,
					24,
					26,
					28,
					30,
					32,
					34,
					36,
					38,
					40,
					42,
					44,
					46,
					48,
					50,
					52,
					54,
					56,
					58,
					60,
					62,
					64,
					66,
					68,
					70,
					72,
					74,
				],
				['!in', 'Viz', 3],
			],
			id: 'Road/label/Rectangle white black (Alt)',
			layout: {
				'icon-image': 'Road/Rectangle white black (Alt)/{_len}',
				'icon-rotation-alignment': 'viewport',
				'symbol-avoid-edges': true,
				'symbol-placement': 'line',
				'symbol-spacing': 800,
				'text-field': '{_name}',
				'text-font': ['Ubuntu Medium'],
				'text-max-angle': 25,
				'text-max-width': 6,
				'text-offset': [0, 0.2],
				'text-padding': 30,
				'text-rotation-alignment': 'viewport',
				'text-size': 10,
			},
			minzoom: 14,
			paint: {
				'text-color': '#a3a3a1',
				'text-halo-color': '#404040',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Road/label',
			type: 'symbol',
		},
		{
			filter: [
				'all',
				[
					'in',
					'_label_class',
					7,
					9,
					11,
					13,
					15,
					17,
					19,
					21,
					23,
					25,
					27,
					29,
					31,
					33,
					35,
					37,
					39,
					41,
					43,
					45,
					47,
					49,
					51,
					53,
					55,
					57,
					59,
					61,
					63,
					65,
					67,
					69,
					71,
					73,
				],
				['!in', 'Viz', 3],
			],
			id: 'Road/label/Rectangle white black',
			layout: {
				'icon-image': 'Road/Rectangle white black/{_len}',
				'icon-rotation-alignment': 'viewport',
				'symbol-avoid-edges': true,
				'symbol-placement': 'line',
				'symbol-spacing': 800,
				'text-field': '{_name}',
				'text-font': ['Ubuntu Medium'],
				'text-max-angle': 25,
				'text-max-width': 6,
				'text-offset': [0, 0.2],
				'text-padding': 30,
				'text-rotation-alignment': 'viewport',
				'text-size': 10,
			},
			minzoom: 14,
			paint: {
				'text-color': '#a3a3a1',
				'text-halo-color': '#404040',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Road/label',
			type: 'symbol',
		},
		{
			id: 'Cemetery/label/Default',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': 0.05,
				'text-max-width': 6,
				'text-padding': 15,
				'text-size': 9.5,
			},
			minzoom: 16,
			paint: {
				'text-color': '#8c8e8d',
				'text-halo-blur': 1,
				'text-halo-color': '#212121',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Cemetery/label',
			type: 'symbol',
		},
		{
			id: 'Freight/label/Default',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': 0.05,
				'text-max-width': 6,
				'text-padding': 15,
				'text-size': 9.5,
			},
			minzoom: 17,
			paint: {
				'text-color': '#808080',
				'text-halo-blur': 1,
				'text-halo-color': '#212121',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Freight/label',
			type: 'symbol',
		},
		{
			id: 'Water and wastewater/label/Default',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': 0.05,
				'text-max-width': 6,
				'text-padding': 15,
				'text-size': 9.5,
			},
			minzoom: 17,
			paint: {
				'text-color': '#808080',
				'text-halo-blur': 1,
				'text-halo-color': '#212121',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Water and wastewater/label',
			type: 'symbol',
		},
		{
			id: 'Port/label/Default',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': 0.05,
				'text-max-width': 6,
				'text-padding': 15,
				'text-size': 9.5,
			},
			minzoom: 17,
			paint: {
				'text-color': '#808080',
				'text-halo-blur': 1,
				'text-halo-color': '#212121',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Port/label',
			type: 'symbol',
		},
		{
			id: 'Industry/label/Default',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': 0.05,
				'text-max-width': 6,
				'text-padding': 15,
				'text-size': 9.5,
			},
			minzoom: 17,
			paint: {
				'text-color': '#808080',
				'text-halo-blur': 1,
				'text-halo-color': '#212121',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Industry/label',
			type: 'symbol',
		},
		{
			id: 'Government/label/Default',
			layout: {
				'symbol-avoid-edges': false,
				'text-allow-overlap': false,
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': 0.05,
				'text-max-width': 6,
				'text-padding': 15,
				'text-size': 9.5,
			},
			minzoom: 17,
			paint: {
				'text-color': '#808080',
				'text-halo-blur': 1,
				'text-halo-color': '#212121',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Government/label',
			type: 'symbol',
		},
		{
			id: 'Finance/label/Default',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': 0.05,
				'text-max-width': 6,
				'text-padding': 15,
				'text-size': 9.5,
			},
			minzoom: 17,
			paint: {
				'text-color': '#808080',
				'text-halo-blur': 1,
				'text-halo-color': '#212121',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Finance/label',
			type: 'symbol',
		},
		{
			id: 'Emergency/label/Default',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': 0.05,
				'text-max-width': 6,
				'text-padding': 15,
				'text-size': 9.5,
			},
			minzoom: 17,
			paint: {
				'text-color': '#808080',
				'text-halo-blur': 1,
				'text-halo-color': '#212121',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Emergency/label',
			type: 'symbol',
		},
		{
			id: 'Indigenous/label/Default',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': 0.05,
				'text-max-width': 6,
				'text-padding': 15,
				'text-size': 9.5,
			},
			minzoom: 17,
			paint: {
				'text-color': '#808080',
				'text-halo-blur': 1,
				'text-halo-color': '#212121',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Indigenous/label',
			type: 'symbol',
		},
		{
			id: 'Military/label/Default',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': 0.05,
				'text-max-width': 6,
				'text-padding': 25,
				'text-size': 9.5,
			},
			minzoom: 17,
			paint: {
				'text-color': '#808080',
				'text-halo-blur': 1,
				'text-halo-color': '#212121',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Military/label',
			type: 'symbol',
		},
		{
			id: 'Transportation/label/Default',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': 0.05,
				'text-max-width': 6,
				'text-padding': 15,
				'text-size': 9.5,
			},
			minzoom: 17,
			paint: {
				'text-color': '#808080',
				'text-halo-blur': 1,
				'text-halo-color': '#212121',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Transportation/label',
			type: 'symbol',
		},
		{
			id: 'Pedestrian/label/Default',
			layout: {
				'symbol-avoid-edges': false,
				'text-allow-overlap': false,
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': 0.05,
				'text-max-width': 6,
				'text-padding': 15,
				'text-size': 9.5,
			},
			minzoom: 17,
			paint: {
				'text-color': '#808080',
				'text-halo-blur': 1,
				'text-halo-color': '#212121',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Pedestrian/label',
			type: 'symbol',
		},
		{
			id: 'Beach/label/Default',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': 0.08,
				'text-max-width': 6,
				'text-padding': 15,
				'text-size': 9.5,
			},
			minzoom: 17,
			paint: {
				'text-color': '#808080',
				'text-halo-blur': 1,
				'text-halo-color': '#212121',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Beach/label',
			type: 'symbol',
		},
		{
			id: 'Golf course/label/Default',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': 0.05,
				'text-max-width': 6,
				'text-padding': 15,
				'text-size': 9.5,
			},
			minzoom: 17,
			paint: {
				'text-color': '#8c8e8d',
				'text-halo-blur': 1,
				'text-halo-color': '#212121',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Golf course/label',
			type: 'symbol',
		},
		{
			id: 'Zoo/label/Default',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': 0.05,
				'text-max-width': 6,
				'text-padding': 15,
				'text-size': 9.5,
			},
			minzoom: 15,
			paint: {
				'text-color': '#8c8e8d',
				'text-halo-blur': 1,
				'text-halo-color': '#212121',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Zoo/label',
			type: 'symbol',
		},
		{
			id: 'Retail/label/Default',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': 0.05,
				'text-max-width': 6,
				'text-padding': 15,
				'text-size': 9.5,
			},
			minzoom: 17,
			paint: {
				'text-color': '#808080',
				'text-halo-blur': 1,
				'text-halo-color': '#212121',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Retail/label',
			type: 'symbol',
		},
		{
			id: 'Landmark/label/Default',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': 0.05,
				'text-max-width': 6,
				'text-padding': 15,
				'text-size': 9.5,
			},
			minzoom: 17,
			paint: {
				'text-color': '#808080',
				'text-halo-blur': 1,
				'text-halo-color': '#212121',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Landmark/label',
			type: 'symbol',
		},
		{
			id: 'Openspace or forest/label/Default',
			layout: {
				'symbol-avoid-edges': false,
				'text-allow-overlap': false,
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': 0.05,
				'text-max-width': 6,
				'text-padding': 25,
				'text-size': 9.5,
			},
			minzoom: 14,
			paint: {
				'text-color': '#8c8e8d',
				'text-halo-blur': 1,
				'text-halo-color': '#212121',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Openspace or forest/label',
			type: 'symbol',
		},
		{
			id: 'Park or farming/label/Default',
			layout: {
				'symbol-avoid-edges': false,
				'text-allow-overlap': false,
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': 0.05,
				'text-max-width': 6,
				'text-padding': 25,
				'text-size': 9.5,
			},
			minzoom: 14,
			paint: {
				'text-color': '#8c8e8d',
				'text-halo-blur': 1,
				'text-halo-color': '#212121',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Park or farming/label',
			type: 'symbol',
		},
		{
			filter: ['==', '_label_class', 1],
			id: 'Point of interest/Park',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-anchor': 'center',
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': 0.08,
				'text-max-width': 6,
				'text-padding': 15,
				'text-size': 9.5,
			},
			minzoom: 14,
			paint: {
				'text-color': '#8c8e8d',
				'text-halo-blur': 1,
				'text-halo-color': '#212121',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Point of interest',
			type: 'symbol',
		},
		{
			id: 'Education/label/Default',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': 0.05,
				'text-max-width': 6,
				'text-padding': 15,
				'text-size': 9.5,
			},
			minzoom: 17,
			paint: {
				'text-color': '#808080',
				'text-halo-blur': 1,
				'text-halo-color': '#212121',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Education/label',
			type: 'symbol',
		},
		{
			id: 'Medical/label/Default',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': 0.05,
				'text-max-width': 6,
				'text-padding': 15,
				'text-size': 9.5,
			},
			minzoom: 17,
			paint: {
				'text-color': '#808080',
				'text-halo-blur': 1,
				'text-halo-color': '#212121',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Medical/label',
			type: 'symbol',
		},
		{
			id: 'Admin1 forest or park/label/Default',
			layout: {
				'symbol-avoid-edges': false,
				'text-allow-overlap': false,
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': 0.05,
				'text-max-width': 6,
				'text-padding': 25,
				'text-size': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[9, 8.5],
						[12, 9.5],
					],
				},
			},
			minzoom: 9,
			paint: {
				'text-color': '#8c8e8d',
				'text-halo-blur': 1,
				'text-halo-color': '#212121',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Admin1 forest or park/label',
			type: 'symbol',
		},
		{
			id: 'Admin0 forest or park/label/Default',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': 0.05,
				'text-max-width': 6,
				'text-padding': 25,
				'text-size': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[9, 8.5],
						[12, 9.5],
					],
				},
			},
			minzoom: 9,
			paint: {
				'text-color': '#8c8e8d',
				'text-halo-blur': 1,
				'text-halo-color': '#212121',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Admin0 forest or park/label',
			type: 'symbol',
		},
		{
			id: 'Airport/label/Airport property',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': 0.05,
				'text-max-width': 6,
				'text-padding': 15,
				'text-size': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[9, 8.5],
						[12, 9.5],
					],
				},
			},
			minzoom: 9,
			paint: {
				'text-color': '#808080',
				'text-halo-blur': 1,
				'text-halo-color': '#212121',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Airport/label',
			type: 'symbol',
		},
		{
			filter: ['==', '_label_class', 1],
			id: 'Admin2 area/label/small',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': 0.2,
				'text-max-width': 6,
				'text-padding': 1,
				'text-size': 9,
				'text-transform': 'uppercase',
			},
			maxzoom: 11,
			minzoom: 9,
			paint: {
				'text-color': '#656564',
				'text-halo-blur': 1,
				'text-halo-color': '#323333',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Admin2 area/label',
			type: 'symbol',
		},
		{
			filter: ['==', '_label_class', 0],
			id: 'Admin2 area/label/large',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': 0.2,
				'text-max-width': 6,
				'text-padding': 1,
				'text-size': 10,
				'text-transform': 'uppercase',
			},
			maxzoom: 11,
			minzoom: 9,
			paint: {
				'text-color': '#656564',
				'text-halo-blur': 1,
				'text-halo-color': '#323333',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Admin2 area/label',
			type: 'symbol',
		},
		{
			filter: ['==', '_label_class', 5],
			id: 'Admin1 area/label/x small',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[4, 0.1],
						[8, 0.18],
					],
				},
				'text-max-width': 6,
				'text-padding': 1,
				'text-size': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[4, 9],
						[5, 9],
						[6, 9.5],
						[9, 10],
					],
				},
				'text-transform': 'uppercase',
			},
			maxzoom: 10,
			minzoom: 5,
			paint: {
				'text-color': '#727271',
				'text-halo-blur': 1,
				'text-halo-color': '#323333',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Admin1 area/label',
			type: 'symbol',
		},
		{
			filter: ['==', '_label_class', 4],
			id: 'Admin1 area/label/small',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[4, 0.1],
						[8, 0.18],
					],
				},
				'text-max-width': 6,
				'text-padding': 1,
				'text-size': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[4, 9],
						[5, 9],
						[6, 9.5],
						[9, 11.5],
					],
				},
				'text-transform': 'uppercase',
			},
			maxzoom: 10,
			minzoom: 5,
			paint: {
				'text-color': '#727271',
				'text-halo-blur': 1,
				'text-halo-color': '#323333',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Admin1 area/label',
			type: 'symbol',
		},
		{
			filter: ['==', '_label_class', 3],
			id: 'Admin1 area/label/medium',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[4, 0.1],
						[8, 0.18],
					],
				},
				'text-max-width': 6,
				'text-padding': 1,
				'text-size': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[4, 10],
						[5, 10],
						[6, 10.5],
						[9, 12],
					],
				},
				'text-transform': 'uppercase',
			},
			maxzoom: 10,
			minzoom: 5,
			paint: {
				'text-color': '#727271',
				'text-halo-blur': 1,
				'text-halo-color': '#323333',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Admin1 area/label',
			type: 'symbol',
		},
		{
			filter: ['==', '_label_class', 2],
			id: 'Admin1 area/label/large',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[4, 0.1],
						[8, 0.2],
					],
				},
				'text-max-width': 6,
				'text-padding': 1,
				'text-size': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[4, 10],
						[5, 10.5],
						[6, 11],
						[9, 15],
					],
				},
				'text-transform': 'uppercase',
			},
			maxzoom: 10,
			minzoom: 5,
			paint: {
				'text-color': '#727271',
				'text-halo-blur': 1,
				'text-halo-color': '#323333',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Admin1 area/label',
			type: 'symbol',
		},
		{
			filter: ['==', '_label_class', 1],
			id: 'Admin1 area/label/x large',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[4, 0.13],
						[8, 0.3],
					],
				},
				'text-max-width': 6,
				'text-padding': 1,
				'text-size': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[4, 10.5],
						[5, 11],
						[6, 11.5],
						[9, 17],
					],
				},
				'text-transform': 'uppercase',
			},
			maxzoom: 10,
			minzoom: 5,
			paint: {
				'text-color': '#727271',
				'text-halo-blur': 1,
				'text-halo-color': '#323333',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Admin1 area/label',
			type: 'symbol',
		},
		{
			filter: ['==', '_label_class', 0],
			id: 'Admin1 area/label/2x large',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[4, 0.13],
						[8, 0.4],
					],
				},
				'text-max-width': 6,
				'text-padding': 1,
				'text-size': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[4, 11],
						[5, 11.5],
						[6, 12],
						[9, 17.5],
					],
				},
				'text-transform': 'uppercase',
			},
			maxzoom: 10,
			minzoom: 5,
			paint: {
				'text-color': '#727271',
				'text-halo-blur': 1,
				'text-halo-color': '#323333',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Admin1 area/label',
			type: 'symbol',
		},
		{
			filter: ['==', '_label_class', 0],
			id: 'Point of interest/General',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-anchor': 'center',
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': 0.08,
				'text-max-width': 6,
				'text-padding': 15,
				'text-size': 9,
			},
			minzoom: 17,
			paint: {
				'text-color': '#808080',
				'text-halo-blur': 1,
				'text-halo-color': '#212121',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Point of interest',
			type: 'symbol',
		},
		{
			filter: ['==', '_symbol', 2],
			id: 'Point of interest/Bus station',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': 0.04,
				'text-max-width': 6,
				'text-offset': [0, -0.9],
				'text-padding': 5,
				'text-size': 9,
			},
			minzoom: 17,
			paint: {
				'text-color': '#808080',
				'text-halo-blur': 1,
				'text-halo-color': '#212121',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Point of interest',
			type: 'symbol',
		},
		{
			filter: ['==', '_symbol', 3],
			id: 'Point of interest/Rail station',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': 0.04,
				'text-max-width': 6,
				'text-offset': [0, -0.9],
				'text-padding': 5,
				'text-size': 9,
			},
			minzoom: 17,
			paint: {
				'text-color': '#808080',
				'text-halo-blur': 1,
				'text-halo-color': '#212121',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Point of interest',
			type: 'symbol',
		},
		{
			id: 'Neighborhood',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Regular'],
				'text-letter-spacing': 0.08,
				'text-padding': 1,
				'text-size': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[10, 10],
						[16, 13],
					],
				},
			},
			maxzoom: 17,
			minzoom: 15,
			paint: {
				'text-color': '#d6d8d4',
				'text-halo-blur': 1,
				'text-halo-color': '#323333',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Neighborhood',
			type: 'symbol',
		},
		{
			filter: ['==', '_label_class', 5],
			id: 'City large scale/town small',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Regular'],
				'text-letter-spacing': 0.08,
				'text-max-width': 6,
				'text-padding': 15,
				'text-size': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[10, 10],
						[16, 13],
					],
				},
			},
			maxzoom: 17,
			minzoom: 10,
			paint: {
				'text-color': '#d6d8d4',
				'text-halo-blur': 1,
				'text-halo-color': '#323333',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'City large scale',
			type: 'symbol',
		},
		{
			filter: ['==', '_label_class', 4],
			id: 'City large scale/town large',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Regular'],
				'text-letter-spacing': 0.09,
				'text-max-width': 6,
				'text-padding': 1,
				'text-size': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[10, 10],
						[16, 15],
					],
				},
			},
			maxzoom: 17,
			minzoom: 10,
			paint: {
				'text-color': '#d6d8d4',
				'text-halo-blur': 1,
				'text-halo-color': '#323333',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'City large scale',
			type: 'symbol',
		},
		{
			filter: ['==', '_label_class', 3],
			id: 'City large scale/small',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Regular'],
				'text-letter-spacing': 0.1,
				'text-max-width': 6,
				'text-padding': 1,
				'text-size': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[10, 11],
						[16, 16],
					],
				},
			},
			maxzoom: 17,
			minzoom: 10,
			paint: {
				'text-color': '#d6d8d4',
				'text-halo-blur': 1,
				'text-halo-color': '#323333',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'City large scale',
			type: 'symbol',
		},
		{
			filter: ['==', '_label_class', 2],
			id: 'City large scale/medium',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': 0.1,
				'text-max-width': 6,
				'text-padding': 1,
				'text-size': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[10, 11],
						[16, 18.5],
					],
				},
			},
			maxzoom: 17,
			minzoom: 10,
			paint: {
				'text-color': '#e1e3de',
				'text-halo-blur': 1,
				'text-halo-color': '#323333',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'City large scale',
			type: 'symbol',
		},
		{
			filter: ['==', '_label_class', 1],
			id: 'City large scale/large',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': 0.1,
				'text-max-width': 6,
				'text-padding': 1,
				'text-size': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[10, 14],
						[16, 22],
					],
				},
			},
			maxzoom: 17,
			minzoom: 10,
			paint: {
				'text-color': '#e1e3de',
				'text-halo-blur': 1,
				'text-halo-color': '#323333',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'City large scale',
			type: 'symbol',
		},
		{
			filter: ['==', '_label_class', 0],
			id: 'City large scale/x large',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name_global}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': 0.1,
				'text-max-width': 6,
				'text-padding': 1,
				'text-size': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[10, 15],
						[16, 25],
					],
				},
			},
			maxzoom: 17,
			minzoom: 10,
			paint: {
				'text-color': '#e1e3de',
				'text-halo-blur': 1,
				'text-halo-color': '#323333',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'City large scale',
			type: 'symbol',
		},
		{
			filter: ['==', '_symbol', 17],
			id: 'City small scale/town small non capital',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name}',
				'text-font': ['Ubuntu Regular'],
				'text-letter-spacing': 0.05,
				'text-max-width': 6,
				'text-padding': 1,
				'text-size': 9.5,
			},
			maxzoom: 10,
			minzoom: 2,
			paint: {
				'text-color': '#d6d8d4',
				'text-halo-blur': 1,
				'text-halo-color': '#323333',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'City small scale',
			type: 'symbol',
		},
		{
			filter: ['==', '_symbol', 15],
			id: 'City small scale/town large non capital',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name}',
				'text-font': ['Ubuntu Regular'],
				'text-letter-spacing': 0.05,
				'text-max-width': 6,
				'text-padding': 1,
				'text-size': 9.5,
			},
			maxzoom: 10,
			minzoom: 2,
			paint: {
				'text-color': '#d6d8d4',
				'text-halo-blur': 1,
				'text-halo-color': '#323333',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'City small scale',
			type: 'symbol',
		},
		{
			filter: ['==', '_symbol', 12],
			id: 'City small scale/small non capital',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name}',
				'text-font': ['Ubuntu Regular'],
				'text-letter-spacing': 0.05,
				'text-max-width': 6,
				'text-padding': 1,
				'text-size': 9.5,
			},
			maxzoom: 10,
			minzoom: 2,
			paint: {
				'text-color': '#d6d8d4',
				'text-halo-blur': 1,
				'text-halo-color': '#323333',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'City small scale',
			type: 'symbol',
		},
		{
			filter: ['==', '_symbol', 9],
			id: 'City small scale/medium non capital',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name}',
				'text-font': ['Ubuntu Regular'],
				'text-letter-spacing': 0.05,
				'text-max-width': 6,
				'text-padding': 1,
				'text-size': 9.5,
			},
			maxzoom: 10,
			minzoom: 2,
			paint: {
				'text-color': '#d6d8d4',
				'text-halo-blur': 1,
				'text-halo-color': '#323333',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'City small scale',
			type: 'symbol',
		},
		{
			filter: ['==', '_symbol', 18],
			id: 'City small scale/other capital',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name}',
				'text-font': ['Ubuntu Regular'],
				'text-letter-spacing': 0.05,
				'text-max-width': 6,
				'text-padding': 1,
				'text-size': 9.5,
			},
			maxzoom: 10,
			minzoom: 2,
			paint: {
				'text-color': '#d6d8d4',
				'text-halo-blur': 1,
				'text-halo-color': '#323333',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'City small scale',
			type: 'symbol',
		},
		{
			filter: ['==', '_symbol', 14],
			id: 'City small scale/town large other capital',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name}',
				'text-font': ['Ubuntu Regular'],
				'text-letter-spacing': 0.05,
				'text-max-width': 6,
				'text-padding': 1,
				'text-size': 9.5,
			},
			maxzoom: 10,
			minzoom: 2,
			paint: {
				'text-color': '#d6d8d4',
				'text-halo-blur': 1,
				'text-halo-color': '#323333',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'City small scale',
			type: 'symbol',
		},
		{
			filter: ['==', '_symbol', 11],
			id: 'City small scale/small other capital',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name}',
				'text-font': ['Ubuntu Regular'],
				'text-letter-spacing': 0.05,
				'text-max-width': 6,
				'text-padding': 1,
				'text-size': 9.5,
			},
			maxzoom: 10,
			minzoom: 2,
			paint: {
				'text-color': '#d6d8d4',
				'text-halo-blur': 1,
				'text-halo-color': '#323333',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'City small scale',
			type: 'symbol',
		},
		{
			filter: ['==', '_symbol', 8],
			id: 'City small scale/medium other capital',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name}',
				'text-font': ['Ubuntu Regular'],
				'text-letter-spacing': 0.05,
				'text-max-width': 6,
				'text-padding': 1,
				'text-size': 9.5,
			},
			maxzoom: 10,
			minzoom: 2,
			paint: {
				'text-color': '#d6d8d4',
				'text-halo-blur': 1,
				'text-halo-color': '#323333',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'City small scale',
			type: 'symbol',
		},
		{
			filter: ['==', '_label_class', 5],
			id: 'Admin0 point/x small',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name}',
				'text-font': ['Ubuntu Bold'],
				'text-letter-spacing': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[5, 0.13],
						[8, 0.5],
					],
				},
				'text-max-width': 6,
				'text-padding': 1,
				'text-size': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[5, 10],
						[10, 12.5],
					],
				},
				'text-transform': 'uppercase',
			},
			maxzoom: 10,
			minzoom: 5,
			paint: {
				'text-color': '#838381',
				'text-halo-blur': 1,
				'text-halo-color': '#323333',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Admin0 point',
			type: 'symbol',
		},
		{
			filter: ['==', '_label_class', 4],
			id: 'Admin0 point/small',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name}',
				'text-font': ['Ubuntu Bold'],
				'text-letter-spacing': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[4, 0.13],
						[8, 0.5],
					],
				},
				'text-max-width': 6,
				'text-padding': 1,
				'text-size': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[4, 10],
						[10, 12.5],
					],
				},
				'text-transform': 'uppercase',
			},
			maxzoom: 10,
			minzoom: 4,
			paint: {
				'text-color': '#838381',
				'text-halo-blur': 1,
				'text-halo-color': '#323333',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Admin0 point',
			type: 'symbol',
		},
		{
			filter: ['==', '_label_class', 3],
			id: 'Admin0 point/medium',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name}',
				'text-font': ['Ubuntu Bold'],
				'text-letter-spacing': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[2, 0.13],
						[8, 0.5],
					],
				},
				'text-max-width': 6,
				'text-padding': 1,
				'text-size': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[2, 8],
						[4, 10],
						[10, 16],
					],
				},
				'text-transform': 'uppercase',
			},
			maxzoom: 10,
			minzoom: 2,
			paint: {
				'text-color': '#838381',
				'text-halo-blur': 1,
				'text-halo-color': '#323333',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Admin0 point',
			type: 'symbol',
		},
		{
			filter: ['==', '_label_class', 2],
			id: 'Admin0 point/large',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name}',
				'text-font': ['Ubuntu Bold'],
				'text-letter-spacing': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[2, 0.13],
						[8, 0.5],
					],
				},
				'text-max-width': 6,
				'text-padding': 1,
				'text-size': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[2, 9],
						[4, 10],
						[6, 16],
					],
				},
				'text-transform': 'uppercase',
			},
			maxzoom: 10,
			minzoom: 2,
			paint: {
				'text-color': '#838381',
				'text-halo-blur': 1,
				'text-halo-color': '#323333',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Admin0 point',
			type: 'symbol',
		},
		{
			filter: ['==', '_label_class', 1],
			id: 'Admin0 point/x large',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name}',
				'text-font': ['Ubuntu Bold'],
				'text-letter-spacing': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[2, 0.15],
						[6, 0.5],
					],
				},
				'text-line-height': 1.5,
				'text-max-width': 6,
				'text-padding': 1,
				'text-size': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[2, 9],
						[4, 10],
						[6, 16],
					],
				},
				'text-transform': 'uppercase',
			},
			maxzoom: 8,
			minzoom: 2,
			paint: {
				'text-color': '#838381',
				'text-halo-blur': 1,
				'text-halo-color': '#323333',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Admin0 point',
			type: 'symbol',
		},
		{
			filter: ['==', '_symbol', 16],
			id: 'City small scale/town small admin0 capital',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': 0.05,
				'text-max-width': 6,
				'text-padding': 1,
				'text-size': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[3, 10],
						[6, 10.5],
						[8, 13],
					],
				},
			},
			maxzoom: 10,
			minzoom: 2,
			paint: {
				'text-color': '#e1e3de',
				'text-halo-blur': 1,
				'text-halo-color': '#323333',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'City small scale',
			type: 'symbol',
		},
		{
			filter: ['==', '_symbol', 13],
			id: 'City small scale/town large admin0 capital',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': 0.05,
				'text-max-width': 6,
				'text-padding': 1,
				'text-size': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[3, 10],
						[6, 10.5],
						[8, 13],
					],
				},
			},
			maxzoom: 10,
			minzoom: 2,
			paint: {
				'text-color': '#e1e3de',
				'text-halo-blur': 1,
				'text-halo-color': '#323333',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'City small scale',
			type: 'symbol',
		},
		{
			filter: ['==', '_symbol', 10],
			id: 'City small scale/small admin0 capital',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': 0.05,
				'text-max-width': 6,
				'text-padding': 1,
				'text-size': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[3, 10],
						[6, 10.5],
						[8, 13],
					],
				},
			},
			maxzoom: 10,
			minzoom: 2,
			paint: {
				'text-color': '#e1e3de',
				'text-halo-blur': 1,
				'text-halo-color': '#323333',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'City small scale',
			type: 'symbol',
		},
		{
			filter: ['==', '_symbol', 7],
			id: 'City small scale/medium admin0 capital',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': 0.05,
				'text-max-width': 6,
				'text-padding': 1,
				'text-size': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[3, 10],
						[6, 10.5],
						[8, 13],
					],
				},
			},
			maxzoom: 10,
			minzoom: 2,
			paint: {
				'text-color': '#e1e3de',
				'text-halo-blur': 1,
				'text-halo-color': '#323333',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'City small scale',
			type: 'symbol',
		},
		{
			filter: ['==', '_symbol', 5],
			id: 'City small scale/large other capital',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': 0.05,
				'text-max-width': 6,
				'text-padding': 1,
				'text-size': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[3, 10],
						[6, 11],
						[8, 14],
					],
				},
			},
			maxzoom: 10,
			minzoom: 2,
			paint: {
				'text-color': '#e1e3de',
				'text-halo-blur': 1,
				'text-halo-color': '#323333',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'City small scale',
			type: 'symbol',
		},
		{
			filter: ['==', '_symbol', 2],
			id: 'City small scale/x large admin2 capital',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': 0.05,
				'text-max-width': 6,
				'text-padding': 1,
				'text-size': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[3, 11],
						[6, 12],
						[8, 15],
					],
				},
			},
			maxzoom: 10,
			minzoom: 2,
			paint: {
				'text-color': '#e1e3de',
				'text-halo-blur': 1,
				'text-halo-color': '#323333',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'City small scale',
			type: 'symbol',
		},
		{
			filter: ['==', '_symbol', 6],
			id: 'City small scale/large non capital',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': 0.05,
				'text-max-width': 6,
				'text-padding': 1,
				'text-size': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[3, 10],
						[6, 11],
						[8, 14],
					],
				},
			},
			maxzoom: 10,
			minzoom: 2,
			paint: {
				'text-color': '#e1e3de',
				'text-halo-blur': 1,
				'text-halo-color': '#323333',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'City small scale',
			type: 'symbol',
		},
		{
			filter: ['==', '_symbol', 4],
			id: 'City small scale/large admin0 capital',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': 0.05,
				'text-max-width': 6,
				'text-padding': 1,
				'text-size': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[3, 10],
						[6, 11],
						[8, 14],
					],
				},
			},
			maxzoom: 10,
			minzoom: 2,
			paint: {
				'text-color': '#e1e3de',
				'text-halo-blur': 1,
				'text-halo-color': '#323333',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'City small scale',
			type: 'symbol',
		},
		{
			filter: ['==', '_symbol', 3],
			id: 'City small scale/x large non capital',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': 0.05,
				'text-max-width': 6,
				'text-padding': 1,
				'text-size': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[3, 11],
						[6, 12],
						[8, 15],
					],
				},
			},
			maxzoom: 10,
			minzoom: 2,
			paint: {
				'text-color': '#e1e3de',
				'text-halo-blur': 1,
				'text-halo-color': '#323333',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'City small scale',
			type: 'symbol',
		},
		{
			filter: ['==', '_symbol', 1],
			id: 'City small scale/x large admin1 capital',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': 0.05,
				'text-max-width': 6,
				'text-padding': 1,
				'text-size': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[3, 11],
						[6, 12],
						[8, 15],
					],
				},
			},
			maxzoom: 10,
			minzoom: 2,
			paint: {
				'text-color': '#e1e3de',
				'text-halo-blur': 1,
				'text-halo-color': '#323333',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'City small scale',
			type: 'symbol',
		},
		{
			filter: ['==', '_symbol', 0],
			id: 'City small scale/x large admin0 capital',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name}',
				'text-font': ['Ubuntu Medium'],
				'text-letter-spacing': 0.05,
				'text-max-width': 6,
				'text-padding': 1,
				'text-size': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[3, 11],
						[6, 12],
						[8, 15],
					],
				},
			},
			maxzoom: 10,
			minzoom: 2,
			paint: {
				'text-color': '#e1e3de',
				'text-halo-blur': 1,
				'text-halo-color': '#323333',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'City small scale',
			type: 'symbol',
		},
		{
			filter: ['==', '_label_class', 0],
			id: 'Admin0 point/2x large',
			layout: {
				'symbol-avoid-edges': true,
				'text-allow-overlap': false,
				'text-field': '{_name}',
				'text-font': ['Ubuntu Bold'],
				'text-letter-spacing': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[2, 0.3],
						[5, 0.5],
					],
				},
				'text-line-height': 1.7,
				'text-max-width': 6,
				'text-padding': 1,
				'text-size': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[2, 12],
						[4, 15.5],
						[5, 18],
					],
				},
				'text-transform': 'uppercase',
			},
			maxzoom: 6,
			minzoom: 2,
			paint: {
				'text-color': '#838381',
				'text-halo-blur': 1,
				'text-halo-color': '#323333',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Admin0 point',
			type: 'symbol',
		},
		{
			filter: ['all', ['==', '_label_class', 1], ['in', 'DisputeID', 0]],
			id: 'Disputed label point/Island',
			layout: {
				'icon-allow-overlap': true,
				'icon-image': 'Disputed label point',
				'text-anchor': 'center',
				'text-field': '{_name}',
				'text-font': ['Ubuntu Italic'],
				'text-letter-spacing': 0.13,
				'text-max-width': 4,
				'text-optional': true,
				'text-size': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[6, 7],
						[15, 10],
					],
				},
			},
			minzoom: 6,
			paint: {
				'text-color': '#808080',
				'text-halo-blur': 1,
				'text-halo-color': '#323333',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Disputed label point',
			type: 'symbol',
		},
		{
			filter: ['all', ['==', '_label_class', 0], ['in', 'DisputeID', 1006]],
			id: 'Disputed label point/Waterbody',
			layout: {
				'icon-allow-overlap': true,
				'icon-image': 'Disputed label point',
				'text-anchor': 'center',
				'text-field': '{_name}',
				'text-font': ['Ubuntu Medium Italic'],
				'text-letter-spacing': 0.1,
				'text-max-width': 6,
				'text-optional': true,
				'text-size': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[2, 8],
						[6, 9.3],
					],
				},
			},
			maxzoom: 10,
			minzoom: 2,
			paint: {
				'text-color': '#707374',
				'text-halo-blur': 1,
				'text-halo-color': 'rgba(12, 36, 56, 1)',
				'text-halo-width': 0.5,
			},
			source: 'esri',
			'source-layer': 'Disputed label point',
			type: 'symbol',
		},
		{
			filter: ['all', ['==', '_label_class', 2], ['in', 'DisputeID', 1021]],
			id: 'Disputed label point/Admin0',
			layout: {
				'icon-allow-overlap': true,
				'icon-image': 'Disputed label point',
				'text-anchor': 'center',
				'text-field': '{_name}',
				'text-font': ['Ubuntu Bold'],
				'text-letter-spacing': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[2, 0.13],
						[8, 0.5],
					],
				},
				'text-max-width': 6,
				'text-optional': true,
				'text-size': <DataDrivenPropertyValueSpecification<number>>{
					stops: [
						[2, 8],
						[4, 10],
						[10, 16],
					],
				},
				'text-transform': 'uppercase',
			},
			minzoom: 2,
			paint: {
				'text-color': '#838381',
				'text-halo-blur': 1,
				'text-halo-color': '#323333',
				'text-halo-width': 1,
			},
			source: 'esri',
			'source-layer': 'Disputed label point',
			type: 'symbol',
		},
	],
})
