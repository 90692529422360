export const FiveGMesh = ({ ...props }) => (
	<svg
		version="1.1"
		x="0px"
		y="0px"
		viewBox="0 0 212.80001 212.8"
		width="212.8"
		height="212.8"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
		style={{ padding: '0.25rem' }}
	>
		<g transform="translate(0,7.4000015)">
			<g transform="translate(-29.3,-27)">
				<path
					fill="#fbf2e8"
					d="m 194.2,158.5 c -0.8,-12.3 2.1,-24.5 8.4,-35.5 L 242.1,54.6 194.3,27 154.8,95.4 c -13.7,23.7 -18.7,50.8 -14.5,77.5 z"
				/>
				<path
					fill="#fbf2e8"
					d="M 77.2,158.5 C 78,146.2 75.1,134 68.8,123 L 29.3,54.6 77.1,27 116.6,95.4 c 13.7,23.7 18.7,50.8 14.5,77.5 z"
				/>
			</g>
			<g transform="translate(-29.3,-27)">
				<path
					fill="#fbf2e8"
					d="m 33.5,216.1 6.9,-4.6 c 2.5,3.9 4,5.1 6.3,5.1 3,0 4.9,-2.2 4.9,-5.7 0,-3.7 -2.3,-5.8 -6.3,-5.8 -4.3,0 -7.2,2.4 -8.8,4.5 l 1.1,-21.3 h 21 v 8.2 H 46.3 v 2.4 c 0.4,0 0.8,0 1.1,0 7.6,0 13,4.9 13,12.5 0,7.5 -5.7,13.6 -13.7,13.6 -5.7,-0.3 -10.5,-3.4 -13.2,-8.9 z"
				/>
				<path
					fill="#fbf2e8"
					d="m 71.6,206.2 c 0,5.8 4.2,10.3 9.5,10.3 0.3,0 0.6,0 1.1,-0.1 v -6.3 h -5.9 v -8.3 H 91 v 20.4 c -2.5,1.7 -6.1,2.6 -9.8,2.6 -10.8,0 -19,-8 -19,-18.6 0,-10.5 7.9,-18.6 18.9,-18.6 4.4,0 8.2,1.3 10.9,3.8 v 9.7 c -1.3,-2.8 -4.9,-5.2 -9.4,-5.2 -6.2,0 -11,4.4 -11,10.3 z"
				/>
				<path
					fill="#fbf2e8"
					d="m 152.9,224.2 h -8.7 V 205 l -13.7,19.2 h -5.1 v -14.9 l -10.6,14.9 h -10.9 l 26,-36 h 4.2 v 16.5 l 11.7,-16.5 h 7.1 z"
				/>
				<path
					fill="#fbf2e8"
					d="M 164.5,202.2 H 184 v 7.9 h -19.4 c 1.6,3.8 5.5,6.4 10.1,6.4 4.5,0 8,-2.4 9.4,-5.2 v 9.7 c -2.7,2.4 -6.5,3.8 -10.9,3.8 -11,0 -18.9,-8 -18.9,-18.6 0,-10.5 7.9,-18.6 18.9,-18.6 4.4,0 8.2,1.3 10.9,3.8 v 9.7 c -1.3,-2.8 -4.9,-5.2 -9.4,-5.2 -4.7,0 -8.6,2.6 -10.2,6.3 z"
				/>
				<path
					fill="#fbf2e8"
					d="m 198.3,215.3 c -0.3,-6.3 -13,-9.2 -13,-17 0,-6 5,-10.7 11.4,-10.7 7.3,0 12.3,5.6 10.9,13 h -8.7 c 0.9,-4 -0.5,-5 -2.3,-5 -1.5,0 -2.4,1.1 -2.4,2.7 0,5.6 11.8,7.6 11.8,16 0,3.8 -2.6,8.2 -7,9.9 h -11.3 c 3.9,-2.7 10.6,-5.4 10.6,-8.9 z"
				/>
				<path
					fill="#fbf2e8"
					d="m 217.6,188.2 v 13.7 H 229 v -13.7 h 8.9 v 36 H 229 V 210 h -11.4 v 14.2 h -8.9 v -36 z"
				/>
			</g>
		</g>
	</svg>
)
